iraUtils = require('../utils.coffee')
default_mime_type_file = require('../data/default_pic.json')
pic_regexp = /\.(jpe?g|png|gif)$/i


fileInputHelper =
  _init_preview: (elem, nice_id) ->
    preview_container = document.createElement('DIV')
    preview_container.className = 'preview-container'
    preview = document.createElement('IMG')
    preview.id = "preview_#{nice_id}"
    preview.className = 'preview-container-img'
    preview_container.appendChild(preview)
    elem.parentElement.appendChild(preview_container)
    elem.setAttribute('data-preview-id', preview.id)
    preview

  _init_cloudinary_field: (elem) ->
    unless elem.id?
      elem.setAttribute 'id', iraUtils.fieldIdFromFieldName(elem.name)
    elem.type = 'hidden' if elem.type != 'hidden'
    if !document.getElementById 'ira-ext-cloudinary'
      # Lazy load cloudinary dependency
      clib_script = document.createElement('SCRIPT')
      clib_script.type = 'text/javascript'
      clib_script.src = 'https://widget.cloudinary.com/v2.0/global/all.js'
      clib_script.id = 'ira-ext-cloudinary'
      document.head.appendChild(clib_script)
    elem_name = elem.name
    nice_id = iraUtils.fieldIdFromFieldName(elem_name)
    preview = @_init_preview(elem, nice_id)
    preview.setAttribute('data-ira-action', 'cloudinary/open/' + elem.id)

  _init_file_input: (elem) ->
    nice_id = iraUtils.fieldIdFromFieldName(elem.name)
    hidden_field = document.createElement('INPUT')
    hidden_field.id = "real_#{nice_id}"
    hidden_field.type = 'hidden'
    elem_name = elem.name
    elem.setAttribute('name', "fake_#{nice_id}")
    hidden_field.setAttribute('name', elem_name)
    elem.parentElement.appendChild(hidden_field)
    elem.setAttribute('data-real-elem-id', hidden_field.id)
    @_init_preview(elem, nice_id)
    elem.addEventListener 'change', (event) ->
      elem = event.target
      return false unless elem?['iraScopeUuid']?
      scope = iraApp.getScope(elem.iraScopeUuid)
      return false unless scope?
      file = elem.files[0]
      reader = new FileReader()
      reader.addEventListener 'load', ->
        hidden_field = document.getElementById(
          elem.getAttribute('data-real-elem-id'))
        hidden_field.value = @result
        scope.set(hidden_field.name, @result)
      reader.readAsDataURL(file)

  updateFilePreview: (elem, loc_val) ->
    return false unless elem.hasAttribute('data-preview-id')
    preview = document.getElementById \
      elem.getAttribute('data-preview-id')
    return false unless preview?
    default_pix = null
    if !loc_val? and elem.hasAttribute('data-real-elem-id')
      # Classical file input must have a data-real-elem-id attribute
      real_el = document.getElementById \
        elem.getAttribute('data-real-elem-id')
      return false unless real_el?
      loc_val = @get(real_el.name)
      default_pix = real_el.getAttribute('data-ira-default-picture')
    if elem.getAttribute('data-ira-widget') == 'cloudinary'
      # Cloudinary element should not have a data-real-elem-id
      # attribute, but shit happens, thus we don't use else here, in
      # case of weird case. It doesn't hurt to keep it that way too.
      loc_val = loc_val ? @get(elem.name)
      if 'cloudinary' of iraApp.config.filters and \
         typeof(iraApp.config.filters.cloudinary) == 'function'
        loc_val = iraApp.config.filters.cloudinary.call \
          null, loc_val, ['c_crop,g_custom']
      default_pix = elem.getAttribute('data-ira-default-picture')
    if !iraUtils.isPresent(loc_val)
      return true unless default_pix?
      loc_val = default_pix
    if loc_val.startsWith('data:image/') or pic_regexp.test(loc_val)
      preview.src = loc_val
    else
      prev_data = default_mime_type_file
      preview.src = prev_data.src if prev_data?
    prev_title = elem.getAttribute('data-ira-preview-title')
    preview.title = prev_title if prev_title?
    true


module.exports = fileInputHelper
