# Verify the form field values
#
# @mixin
#
checkFieldsHelper =
  _extract_constraints: ->
    unless @config['symbols']?
      @config['symbols'] = {}
    @config.symbols['constraints'] = {}
    if 'models' of @config.symbols
      for model_name, conf of @config.symbols.models
        for f, fconf of conf
          continue if f[0] == '_'
          key = "#{model_name}.#{f}"
          @config.symbols['constraints'][key] = fconf

  check_field: (key, value) ->
    field_name = @keyOrAlias(key)
    if !@config.symbols['constraints']? or \
       !@config.symbols['constraints'][field_name]?
      return [false, 'UNKNOWN']
    fconf = @config.symbols['constraints'][field_name]
    if fconf['read_only']
      return [false, 'READ_ONLY']
    if (!value or value == '') and fconf['required']
      return [false, 'REQUIRED']
    if (fconf['type'] == 'integer' and isNaN(parseInt(value))) or
       (fconf['type'] == 'float' and isNaN(parseFloat(value)))
      return [false, 'TYPE_ERROR', 'number']
    if fconf['type'] == 'enum' and fconf['values']? and \
       value not in fconf['values']
      return [false, 'TYPE_ERROR', 'enum', fconf['values']]
    if fconf['max_len']? and value.length > fconf['max_len']
      return [false, 'LENGTH_ERROR', 'max', fconf['max_len']]
    if 'format' of fconf and fconf['format_type'] == 'regexp'
      pcre_re = new RegExp(/^\/(.+)\/([imsxADSUXu]*)$/)
      pcre_str = pcre_re.exec(fconf['format'])
      unless pcre_str?
        unless @testEnv()
          console.warn "#{fconf['format']} is not a valid regexp"
        return [true]
      flags = (f for f in pcre_str[2].split('') when f in ['i', 'm', 'u'])
      re = new RegExp(pcre_str[1], flags)
      return [false, 'VALUE_ERROR'] unless re.test(value)
    return [true]

module.exports = checkFieldsHelper
