iraModel = require('../model.coffee')
iraMetaController = require('./meta_controller.coffee')


class iraEntitiesController extends iraMetaController
  _cleanup_args_and_feed_req_body: (args) ->
    req_body = {}
    # Remove unwanted system keys. This way, we are agnostic about the
    # other keys, which may be sent to the API
    for key, value of args
      continue if key in ['collection', 'type', 'model', 'id',
                          'from_element', 'from_scope', 'tail']
      if value == ''
        continue unless key in ['reference', 'parent', 'origin']
        # Avoid request without dependencies
        return false
      req_body[key] = value
    if args['model']?
      # Again, avoid request without dependencies
      return false unless args['id']?
      relationship = 'reference'
      p = iraApp.parentingRelationshipName(@api_end_point, args['model'])
      relationship = p if p?
      req_body[relationship] = args['id']
    else if args['id']?
      # Call of a single object without parenting reference
      req_body['id'] = args['id']
    req_body

  # Used to fetch counter values
  counter: (args) ->
    type = args.tail[0]
    return false unless type?
    req_body = @_cleanup_args_and_feed_req_body(args)
    return false unless req_body?
    @_request
      headers: @_auth_header()
      url: @_api_url 'count', "#{@api_end_point}/#{type}"
      body: req_body
      context:
        scope: iraApp.getScope(args['from_scope'])
        counter_tag: args.tail[1]
        entity: @api_end_point
        ctype: type
        method: 'counter'
      success: (data) ->
        return unless @scope?
        @ctype = 'amount_c27n' if @ctype == 'amount'
        return unless data[@entity]?[@ctype]?
        if @ctype == 'number'
          if Array.isArray(data[@entity][@ctype])
            return @scope.set @counter_tag, 0
          return @scope.set @counter_tag, data[@entity][@ctype]
        @scope.updateAmountCounter(@counter_tag, data[@entity][@ctype])

  # View method is used to fetch a single record.
  view: (args = {}) ->
    return false unless @api_end_point?
    return false unless args['id']?
    @_request
      headers: @_auth_header()
      url: @_api_url 'view', args['id']
      context:
        scope: iraApp.getScope(args['from_scope'])
        method: 'view'
      success: (data) ->
        return unless @scope?
        if 'entities' of data
          entity_data = Object.values(data['entities'])?[0]
        else
          return false
        entity = new iraModel(entity_data, @api_end_point)
        @scope.set @api_end_point, entity.data
    true

  # Cancel method is used to destroy collection items.
  cancel: (args = {}) ->
    return false unless @api_end_point?
    return false unless args['id']?
    @_request
      headers: @_auth_header()
      method: 'POST'
      url: @_api_url 'submit'
      body: JSON.stringify \
        "#{@api_end_point}":
          id: args['id']
          state: 'cancel'
      success: (_) =>
        @_redirect_if_needed()
        true
    true

  # Return items from an endpoint.
  #
  # If call with the type `list`, it will return all items from an
  # endpoint without any attempt to triage them, filter them or paginate
  # them. This is usefull to populate a select element.
  #
  # Otherwise (type = search), this method will handle pagination
  # properties, and thus it will filter, sort etc. results.
  #
  # @param args [Object] optional options
  # @option args model [String] dependency model name to filter on
  # @option args id [String] dependency model id to filter on
  # @option args type [String] search or list
  list: (args) ->
    return false unless @api_end_point?

    req_body = @_cleanup_args_and_feed_req_body(args)
    return false unless req_body?

    type = args['type'] ? 'search'
    if type == 'my'
      query_url = @_api_url type, @api_end_point
    else
      query_url = @_api_url 'search'
      type == 'search' if type not in ['search', 'list']
    if type in ['my', 'search']
      # Do not limit for list type
      req_body['per_page'] = 10 unless req_body['per_page']?
      if parseInt(req_body['per_page']) > 50
        req_body['per_page'] = 50

    @_request
      headers: @_auth_header()
      url: query_url
      body: req_body
      context:
        scope: iraApp.getScope(args['from_scope'])
        method: 'list'
      success: (data) ->
        @scope.update(data) if @scope?
    true

  # Prepare query params before calling {iraEntitiesController#list list}.
  #
  # This function prepare stuff before an actual research is done. Thus
  # this function does not initiate any ajax request. See the
  # {iraEntitiesController#list list} method above for that purpose.
  #
  # @param args [Object] optional options
  search: (args) ->
    return false unless args['from_scope']?
    scope = iraApp.getScope(args.from_scope)
    return false unless scope?

    if args['from_element']? and args.from_element.tagName == 'FORM'
      @_enable_again_submit args.from_element

    all_keys = []
    for key, value of scope.formData(stringify: false, with_defaults: false)
      iraApp.main_scope.set(key, value, true)
      all_keys.push key
    iraApp.main_scope.notify all_keys
    true

  next: (args) ->
    scope = null
    scope = iraApp.getScope(args.from_scope) if args['from_scope']?
    if args['from_element']? and args.from_element.tagName == 'FORM'
      # Context save is done by _redirect_if_needed
      @_redirect_if_needed(scope)
      return true
    return false unless scope?
    scope.loadMore()
    true

  previous: (args) ->
    scope = null
    scope = iraApp.getScope(args.from_scope) if args['from_scope']?
    return false unless scope?
    if scope.type == 'form'
      # Context save is done by _redirect_if_needed
      prev_url = scope.get('previous_url')
      if prev_url? and prev_url != ''
        iraApp.main_scope.set('redirect_url', prev_url, true)
        @_redirect_if_needed(scope)
        return true
      return false
    scope.loadMore(direction: 'previous')
    true

  # Generic function to move from one page to another from a pagination
  # bar
  #
  # @param args [Object] optional options
  # @param nextpage [Integer] the page to access. May be null.
  gotopage: (args, nextpage = null) ->
    return false if !args['from_scope']? and !args['from_element']?
    scope = iraApp.getScope(args.from_scope)
    return false unless scope?
    nextpage ?= args.from_element.textContent.trim()
    return false if isNaN(parseInt(nextpage))
    scope.loadMore(nextpage: nextpage)
    true

  # invite form submission method.
  #
  # @param args [Object] optional options
  invite: (args) ->
    # Avoid code duplication
    args['api_method'] = 'invite'
    @submit(args)

  # Generic form submission method.
  #
  # @param args [Object] optional options
  submit: (args) ->
    return false unless args['from_scope']?
    scope = iraApp.getScope(args.from_scope)
    return false unless scope?
    api_method = args['api_method'] ? 'submit'
    @_request
      headers: @_auth_header()
      method: 'POST'
      url: @_api_url(api_method)
      context:
        method: 'submit'
        scope: scope
      body: scope.formData()
      success: (data) ->
        c = new iraMetaController(@api_end_point)
        c._handle_response(data, @scope)
    true


module.exports = iraEntitiesController
