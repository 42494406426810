iraModel = require('../model.coffee')
iraUtils = require('../utils.coffee')

# App persisting and retrieval data methods
#
# @mixin
#
sessionHelper =
  securelyGetDataFromSession: ->
    try
      context_data = sessionStorage.getItem('context')
      throw new Error('IRA_NO_CONTEXT') unless context_data?
      context_data = JSON.parse(context_data)
    catch
      return {}
    context_data

  _securely_get_local_storage: ->
    try
      session_data = localStorage.getItem('session')
      throw new Error('IRA_NO_SESSION') unless session_data?
      session_data = JSON.parse(session_data)
    catch
      return null
    unless iraUtils.isPresent(session_data['user-token-expire'])
      return null
    now = Math.floor(Date.now() / 1000)
    return null if session_data['user-token-expire'] < now
    return null unless iraUtils.isPresent(session_data['user-token'])
    session_data

  # @private
  _load_data_from_session: ->
    @meta_vars.push('contact') unless 'contact' in @meta_vars
    for key, value of @securelyGetDataFromSession()
      if typeof(value) == 'object' and !Array.isArray(value)
        value = new iraModel(value, key).data
      @main_scope.set(key, value, true) if key in @meta_vars
    session_data = @_securely_get_local_storage()
    return unless session_data?
    @main_scope.set 'user-token-expire', \
      session_data['user-token-expire'], true
    @main_scope.set 'user-token', session_data['user-token'], true
    iraUtils.addClass(document.body, 'ira-logged-in')
    for key in ['user', 'currency', 'lang']
      @main_scope.set key, session_data[key], true

  # @private
  _fetch_from_uri_and_metas: ->
    for objname, id of @meta_refs
      if objname in iraApp.allowed_objects
        @main_scope.exec(action: "##{objname}/view/#{id}")
      else
        @main_scope.set "#{objname}.id", id, true
    if document.location.hash != ''
      @main_scope.exec(action: document.location.hash)
    return if document.location.search == ''
    try
      search_data = document.location.search.substr(1).split('&')
    catch
      search_data = []
    for crit in search_data
      [key, value] = crit.split '=', 2
      continue if key[0..3] != 'ira-'
      try
        value = decodeURIComponent(value)
        # Kept if decodeURIComponent doesn't work as expected
        #value = value.replace(/%2F/g, '/')
      catch
        value = null
      continue unless value?
      if key == 'ira-action'
        @main_scope.exec(action: '#' + value)
      else if key == 'ira-token'
        @main_scope.set 'temp-token', value, true
      else if key == 'ira-auth-token'
        @main_scope.set 'temp-token', value, true
        @main_scope.exec(action: '#contact/oauth_callback')
      else
        @main_scope.set key.slice(4), value, true

  # @private
  _parse_metas: ->
    loc_metas = document.querySelectorAll \
      'meta[name^="ira:"],meta[property^="ira:"]'
    meta_done = ['ira:unauthenticated']
    @meta_vars = []
    @meta_refs = {}
    for meta in loc_metas
      meta_name = meta.name
      if meta_name == '' and meta.hasAttribute('property')
        meta_name = meta.getAttribute('property')
      continue unless meta_name?
      continue if meta_name in meta_done
      meta_done.push meta_name
      objname = meta_name.slice(4)
      if meta.hasAttribute('content') and meta.content? and
         meta.content != ''
        @meta_refs[objname] = meta.content
      else if objname not in @meta_vars
        @meta_vars.push objname

  # @private
  _check_user_session: ->
    return if @main_scope.get('isLoggedIn')
    meta = document.querySelector \
      'meta[name="ira:unauthenticated"],meta[property="ira:unauthenticated"]'
    if !meta? or !meta.hasAttribute('content') or meta.content == ''
      return
    document.location = meta.content

  save: (scope) ->
    # If Storage API is not available, do nothing
    return if @main_scope.get('noStorageAPI')
    # we must clone @context, not work on it
    clean_ctx = {}
    for key, value of @main_scope.context
      continue if typeof(value) == 'function'
      clean_ctx[key] = value
    if scope?
      for k, v of scope.formData(stringify: false, save_ext: true)
        clean_ctx[k] = v
      clean_ctx['scope_boundaries'] =
        name: scope.uuid
        fields: scope.boundaries(true)

    sensible_data = ['contact.password', 'contact.confirm-password',
                     'payment.card', 'payment.iban', 'previous_url',
                     'scope_level']
    @main_scope._remove_deep(k, clean_ctx) for k in sensible_data

    session_keys = ['user', 'user-token', 'user-token-expire',
                    'currency', 'lang']
    session_data = {}
    for k in session_keys
      session_data[k] = clean_ctx[k]
      delete clean_ctx[k]

    session_str = JSON.stringify session_data
    all_data_str = JSON.stringify clean_ctx
    try
      localStorage.setItem 'session', session_str
      sessionStorage.setItem 'context', all_data_str
    catch
      console.warn 'Storage API is not available in this browser'

  drop: ->
    iraUtils.removeClass(document.body, 'ira-logged-in')
    @main_scope.remove('contact')
    @main_scope.remove('user')
    @main_scope.remove('user-token')
    @main_scope.remove('user-token-expire')
    sessionStorage.removeItem('context')
    localStorage.removeItem('session')


module.exports = sessionHelper
