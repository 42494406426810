array_regexp = /^(\w+)\[(\d*)\]$/
crxp = '(?:total_)?(?:current|collected)'
crxs = '(?:_percent)?(?:_formatted)?'
crxt = '(amount|number)'
counters_regexp = new RegExp("^(?:([^.]+)\.)?(#{crxp}_(.+)_#{crxt})#{crxs}$")

# Responsible for the feeding of, and value retriving from the scope
# context
#
# @mixin
#
contextHelper =
  _remove_filters: (key) ->
    key.split('|', 1)[0].trim()

  _filter_params: (key, filter) ->
    _k = key.split('|').map( (t) -> t.trim() )
    return null unless _k.length > 1
    for f in _k
      _p = f.split(':')
      return _p[1..] if _p[0] == filter
    null

  _normalize_key: (key) ->
    all_keys = key.split('.')
    new_keys = []
    for cur_key in all_keys
      # Is this an array? Is there a squared brace?
      braceo = cur_key.indexOf('[')
      bracec = cur_key.indexOf(']')
      if braceo == -1 or bracec == -1
        new_keys.push(cur_key)
        continue
      prefix = cur_key[0...braceo]
      while (braceo != -1 and bracec != -1)
        subkey = cur_key[(braceo + 1)...bracec]
        braceo = cur_key.indexOf('[', bracec)
        bracec = cur_key.indexOf(']', bracec + 1)
        if subkey == ''
          console.warn 'Array notation without fixed index: 0 will be used'
          prefix = "#{prefix}[0]"
          continue
        indice = parseInt(subkey)
        if isNaN(indice)
          new_keys.push(prefix)
          prefix = subkey
        else
          prefix = "#{prefix}[#{indice}]"
      new_keys.push(prefix)
    new_keys.join('.')

  _extract_from_array: (matches, obj) ->
    cur_key = matches[1]
    indice = parseInt(matches[2])
    # Do the current target is an array?
    return undefined unless cur_key of obj
    if !Array.isArray(obj[cur_key]) or obj[cur_key].length == 0
      return undefined
    obj[cur_key][indice]

  _key_is_counter: (key) ->
    counters_regexp.resetAndExec key

  # This method return the value associated with the given `key` at any
  # depth of the `obj` object.
  #
  # This method always return a two items array, which first item is the
  # actual found (or not) value and the second is a boolean, which
  # indicates if the `key` was found in `obj` or not. This help to know
  # the real meaning of `undefined` or `null` values.
  #
  # @private
  # @param key [String] the path to the value to retrive inside `obj`
  # @param obj [Object] the object inside which the value is looked for
  # @return [Array] See above for this array definition
  _get_deep: (key, obj) ->
    key = @_normalize_key(key)
    all_keys = key.split('.')
    all_keys_length = all_keys.length
    cur_key_index = 1
    for cur_key in all_keys
      # Is this an array?
      matches = array_regexp.resetAndExec cur_key
      if matches?
        obj = @_extract_from_array matches, obj
        continue

      if !obj?
        # Very simple case, we just have to return
        break if cur_key_index == all_keys_length
        # Otherwise, this means we have to go deeper, but no object is
        # available. The searched variable is not in this scope and we
        # have to ask to this scope's parent.
        return [undefined, false, undefined]

      # Now, obj cannot be null

      if cur_key_index < all_keys_length and typeof(obj) != 'object' and
         typeof(obj) != 'function'
        # Weird, we found a scalar value, but we should go deeper…
        # « These aren't the droids you're looking for »
        return [undefined, false, undefined]

      if typeof(obj) == 'object' and !obj[cur_key]?
        # Simple case, the searched item is just not there.
        # This check avoid one last useless recursive call.
        matches = @_key_is_counter(key)
        return [undefined, (cur_key of obj), undefined] unless matches?
        # Ok, this is a counter tag. For now, we can only return
        # undefined :/ and true to NOT look further in parent scopes
        return [undefined, true, 'counter'].concat(matches)

      if typeof(obj[cur_key]) == 'function'
        # Here we pass the total key string AND the current key because
        # in some case (like with _i10t functions) we cannot be sure of
        # the actual key (function) name and we need to separate that
        # name from the trailing parameters.
        return [obj[cur_key].call(@context, key, cur_key), true, 'function']

      obj = obj[cur_key]
      cur_key_index++
    # All is good, we arrive at destination, far deep in obj
    [obj, true, typeof(obj)]

  _set_deep_array: (matches, keys, obj, value) ->
    cur_key = matches[1]
    indice = parseInt(matches[2])
    # Do the current target is an array?
    if !obj[cur_key]?
      obj[cur_key] = []
      indice = 0 if isNaN(indice)
    else if Array.isArray(obj[cur_key])
      indice = obj[cur_key].length if isNaN(indice)
    else
      console.error 'Undefined or not an array: ', cur_key, obj
      return obj

    if keys.length == 0
      obj[cur_key][indice] = value
      return obj

    cur_obj = obj[cur_key][indice]
    cur_obj = {} if !cur_obj? or typeof(cur_obj) != 'object'
    obj[cur_key][indice] = @_set_deep keys, value, cur_obj
    obj

  # Set a `value` at any depth in the object `obj` following the `keys`
  # path.
  #
  # The `keys` parameter should be an array. If a string is given, it
  # will be split on the `.` char.
  #
  # @private
  # @param keys [mixed] the address of the value in obj
  # @param value [mixed] the value to set
  # @param obj [Object] the object inside which the value must be set
  # @return [mixed] `obj` after modification
  _set_deep: (keys, value, obj) ->
    keys = @_normalize_key(keys).split('.') if typeof(keys) == 'string'
    cur_key = keys.shift()

    # Is this an array?
    matches = array_regexp.resetAndExec cur_key
    return @_set_deep_array matches, keys, obj, value if matches?

    if keys.length == 0
      obj[cur_key] = value
      return obj

    cur_obj = obj[cur_key]
    cur_obj = {} if !cur_obj? or typeof(cur_obj) != 'object'
    obj[cur_key] = @_set_deep keys, value, cur_obj
    obj

  _remove_deep: (keys, obj) ->
    keys = keys.split('.') if typeof(keys) == 'string'
    cur_key = keys.shift()
    return false if !obj[cur_key]?

    if keys.length == 0
      delete obj[cur_key]
      return true

    return false unless typeof(obj[cur_key]) == 'object'
    deep_status = @_remove_deep keys, obj[cur_key]
    delete obj[cur_key] if Object.keys(obj[cur_key]).length == 0
    deep_status

  # Flatten all the keys of an object
  #
  # This method parse each keys of a given object `obj`, named `key` and
  # flatten all its internal keys
  #
  # @private
  # @param key [String] string to prepend behind found keys
  # @param obj [Object] the object to browse
  # @return [Array<String>] the object keys
  _flatten_subkeys: (key, obj) ->
    if !obj? or typeof(obj) != 'object' or Array.isArray(obj)
      # obj is not an obj, nothing to look for here
      return [key]
    pathes = []
    prefix = key
    prefix += '.' if key != ''
    for subkey of obj
      continue if subkey[0] == '_'
      if typeof(obj[subkey]) == 'function'
        pathes = pathes.concat(prefix + subkey)
      else
        pathes = pathes.concat(@_flatten_subkeys(prefix + subkey, obj[subkey]))
    pathes

  updateAmountCounter: (counter_tag, counter_value) ->
    currency = iraApp.main_scope.get('currency')
    # Array may be encountered with no values
    if Array.isArray(counter_value)
      val = { "#{currency}": 0 }
      loc_value = 0
    else
      val = counter_value
      currency = Object.keys(val)[0] unless val[currency]?
      loc_value = val[currency] ? 0.0
    if counter_tag.endsWith('_formatted')
      counter_tag = counter_tag[0..-11]
    if counter_tag.endsWith('_percent')
      counter_tag = counter_tag[0..-9]
    @set counter_tag, loc_value
    @set("#{counter_tag}_formatted",
      parseFloat(loc_value).formatMoney(currency))
    ref_entity = counter_tag.split('.', 2)
    return unless ref_entity[1]?
    exp = @get("#{ref_entity[0]}.collected_amount_expected", true)
    return if !exp[0]? or exp[0] == ''
    return if exp[1] == false
    if exp[0] == 0 or exp[0] == '0'
      pct = 0
    else
      pct = Math.round((parseFloat(loc_value) * 100) / parseFloat(exp[0]))
    @set "#{counter_tag}_percent", pct
    @set "#{counter_tag}_percent_formatted", "#{pct}%"

  # Retrieve a value from the scope context, given a `clean_key`.
  #
  # This is a private method for the public {contextHelper~get get}
  # method.
  #
  # @private
  # @param clean_key [String] the key to fetch
  # @return [mixed] the current value of this key
  _get: (key) ->
    clean_key = iraApp.keyOrAlias(key)
    val = @_get_deep(clean_key, @context)
    # val should ALWAYS be an array of two items
    return val if val[1] == true
    prefix = clean_key.split('.', 2)[0]
    valo = val[0]
    if @type == 'named' and @config['model_as'] in [clean_key, prefix]
      # Do not fetch local var in parent
      return [valo, true, 'boundary']
    unless @parentUuid?
      # If we are on main scope and the key root is contact, and if we
      # have a connected user, we provide the corresponding user key if
      # nothing has been found before.
      if prefix == 'contact' and @type == 'main_scope' and \
         @context['isLoggedIn']? and @context.isLoggedIn()
        sk = clean_key.split('.', 2)
        sk[0] = 'user'
        val = @_get_deep(sk.join('.'), @context)
      return val
    p = iraApp.getScope(@parentUuid)
    return val unless p?
    # Do not ask parent for its muted variable
    if p.type == 'named' and
       p.config['model_as'] in [clean_key, prefix]
      return [undefined, true, 'parent_boundary']
    val = p.get(key, true)
    val[1] = false
    val

  # Retrieve a value from the scope context, given a `key`.
  #
  # The `with_info` param is used to return the whole array internaly
  # returned by {contextHelper~_get_deep _get_deep} to know if the asked
  # key was in this scope or not.
  #
  # This method acts as a proxy for the private
  # {contextHelper~_get _get} method, in order to handle tag filters
  # around the actual result.
  #
  # @param key [String] the key to fetch
  # @param with_info [Boolean] wether to return the _get_deep array
  # @return [mixed] the current value of this key
  get: (key, with_info = false) ->
    filters = key.split('|').map( (t) -> t.trim() )
    key = filters.shift()
    r = @_get key
    if filters.length == 0
      return r if with_info
      return r[0]
    for f in filters
      params = f.split(':').map( (p) -> p.trim() )
      f = params.shift()
      continue unless f of iraApp.config.filters
      continue unless typeof(iraApp.config.filters[f]) == 'function'
      r[0] = iraApp.config.filters[f].call(@context, r[0], params)
    return r if with_info
    r[0]

  # Set a value in the scope context at the given path.
  #
  # The `set_only` parameter determines if the scope must be alerted
  # that a value changed or has been added to its context or not. This
  # notificaton may lead to the scope being repaint.
  #
  # @param key [String] where to store the value
  # @param value [mixed] the value to store
  # @param set_only [Boolean]
  # @return [mixed] the new inserted value
  set: (key, value, set_only = false) ->
    key = iraApp.keyOrAlias(key)
    @context = @_set_deep(key, value, @context)
    if typeof(value) == 'function'
      # Here we pass the total key string AND the last bit of it to
      # emulate a potential _deep_get call, where this function will be
      # called with trailing parameters. We are currently inserting
      # my.function, but later it could be called as
      # my.function.arg1.arg2
      value = value.call @context, key, key.split('.').slice(-1)
    @_revoke_old_condition(key, value)
    unless set_only
      all_keys = @_flatten_subkeys(key, value)
      all_keys.unshift(key) unless key in all_keys
      @notify all_keys, true
    value

  # Remove the value at the given path from the scope context.
  #
  # See the {contextHelper~set set} method for the `remove_only` usage,
  # which is the same as its `set_only` parameter.
  #
  # @param key [String] the path to the value to remove
  # @param remove_only [Boolean]
  remove: (key, remove_only = false) ->
    key = iraApp.keyOrAlias(key)
    unless remove_only
      old_value = @get(key)
      all_keys = @_flatten_subkeys(key, old_value)
      all_keys.unshift(key) unless key in all_keys
    @_remove_deep key.split('.'), @context
    @_revoke_old_condition(key)
    @notify(all_keys, true) unless remove_only


module.exports = contextHelper
