iraUtils = require('../utils.coffee')
iraModel = require('../model.coffee')
iraMetaController = require('./meta_controller.coffee')


class iraContactsController extends iraMetaController
  constructor: ->
    super('contact')

  presignup: (args) ->
    return false unless args['from_scope']?
    scope = iraApp.getScope(args.from_scope)
    return false unless scope?
    # email should be sent as this. Do not encode it in json.
    user_mail = scope.get('contact.email')
    return unless iraUtils.isPresent(user_mail)
    mail_params =
      contact:
        email: user_mail
    @_request
      method: 'POST'
      url: @_api_url 'presignup'
      context:
        scope: scope
        method: 'presignup'
      body: JSON.stringify mail_params
      success: (data) ->
        @scope.set 'showConfirmationCode', true

  signup: (args) ->
    return false unless args['from_scope']?
    scope = iraApp.getScope(args.from_scope)
    return false unless scope?
    @_request
      method: 'POST'
      url: @_api_url 'signup'
      context:
        scope: scope
        method: 'signup'
        me: @
      body: scope.formData()
      success: (data, _, xhr) ->
        @scope.remove 'showConfirmationCode'
        @me._post_login(data, xhr, @scope)

  'forgot-password': (args) ->
    return false unless args['from_scope']?
    scope = iraApp.getScope(args.from_scope)
    return false unless scope?
    form_data = scope.formData(stringify: false)
    if 'reset_password_url' of form_data
      form_data['reset_password_url'] = iraUtils.fullUrl \
        form_data['reset_password_url']
    @_request
      method: 'POST'
      url: @_api_url 'forgot-password'
      context:
        scope: scope
        method: 'forgot-password'
        me: @
      body: JSON.stringify(form_data)
      success: ->
        @me._redirect_if_needed(@scope)
        true

  'reset-password': (args) ->
    return false unless args['from_scope']?
    scope = iraApp.getScope(args.from_scope)
    return false unless scope?
    @_request
      method: 'POST'
      url: @_api_url 'reset-password'
      context:
        scope: scope
        method: 'reset-password'
        me: @
      body: scope.formData()
      success: (data, _, xhr) ->
        @me._post_login(data, xhr, @scope)

  login: (args) ->
    return false unless args['from_scope']?
    scope = iraApp.getScope(args.from_scope)
    return false unless scope?
    if args['from_element']?
      rfield = args['from_element'].querySelector('input[name=redirect_url]')
      if rfield? and iraUtils.isPresent(rfield.value)
        iraApp.main_scope.set('redirect_url', rfield.value)
        iraApp.main_scope.set('successful_url', rfield.value)
    login_params =
      contact:
        email: scope.get('contact.email')
        password: scope.get('contact.password')
    @_request
      method: 'POST'
      url: @_api_url 'login'
      context:
        scope: scope
        method: 'login'
        me: @
      body: JSON.stringify login_params
      success: (data, _, xhr) ->
        @me._post_login(data, xhr, @scope)

  oauth: (args) ->
    return false unless args['id']?
    return false unless args['id'] in ['facebook', 'twitter']
    return false unless args['from_element']?
    return false unless args['from_element'].hasAttribute('href')
    callback_url = iraUtils.fullUrl(args['from_element'].getAttribute('href'))
    callback_url = "#{args['id']}?successful_url=#{callback_url}"
    document.location = @_api_url 'oauth', callback_url

  oauth_callback: ->
    @_clean_session()
    @_request
      method: 'GET'
      url: @_api_url 'whoami'
      context:
        method: 'oauth_callback'
        me: @
      body:
        'ira-token': iraApp.main_scope.get('temp-token')
      success: (data, _, xhr) ->
        delete iraApp.main_scope.remove 'temp-token'
        @me._post_login(data, xhr, null)

  _post_login: (data, xhr, scope) ->
    @_clean_session()
    return @_handle_response(xhr, scope) unless data.success
    if scope?
      scope.remove 'contact.password'
      scope.remove 'contact.confirm-password'
    ms = iraApp.main_scope
    contact = null
    cdata = data['context']?['contact']
    if cdata?
      # We may have no contact in the case of a post-signup validation
      # workflow
      contact = new iraModel(Object.values(cdata)[0], 'contact')
      ms.set 'contact', contact.data
      ms.set 'user', contact.data, true
      ms.set 'user-token', data.token, true
      ms.set 'user-token-expire', data.expire, true
      # Notify once and for all to quicken the process
      ms.notify ['user-token', 'user-token-expire', 'isLoggedIn']
      iraUtils.addClass(document.body, 'ira-logged-in')
    return true if @_redirect_if_needed() == 'redirect'
    return true if !scope? or scope.type != 'form'
    iraUtils.addClass(scope.root, 'ira-success')
    @_enable_again_submit scope.root

  _clean_session: ->
    iraApp.drop()

  logout: (args) ->
    @_clean_session()
    return false unless args['from_scope']?
    scope = iraApp.getScope(args.from_scope)
    return false unless scope?
    # Force page refresh to take drop into account
    new_uri = document.createElement('A')
    new_uri.href = document.location
    search_data = decodeURIComponent(new_uri.search.slice(1))
    query = search_data.split('&')
    new_query = []
    for crit in query
      continue if crit == ''
      if crit.startsWith('ira-action=contact') or \
         crit.startsWith('ira-action=user') or \
         crit.startsWith('ira-action=logout')
        continue
      new_query.push crit
    new_query.push 'logout'
    new_uri.hash = ''
    new_uri.search = '?' + new_query.join('&')
    document.location = new_uri.toString()


module.exports = iraContactsController
