iraUtils =
  uniqueId: (length = 25) ->
    id = ''
    id += Math.random().toString(36).substr(2) while id.length < length
    id.substr 0, length

  include: (obj, mixin) ->
    obj.prototype[name] = method for name, method of mixin
    obj.prototype

  defaultDisplay: (el_name) ->
    unless window['iraElDisplayCache']?
      window['iraElDisplayCache'] = {}
    if iraElDisplayCache[el_name]?
      return iraElDisplayCache[el_name]
    el = document.createElement(el_name)
    document.body.appendChild(el)
    display = getComputedStyle(el).getPropertyValue('display')
    el.parentElement.removeChild(el)
    display = 'block' if display == 'none'
    iraElDisplayCache[el_name] = display
    display

  showElement: (el) ->
    cur_style = getComputedStyle(el).getPropertyValue('display')
    return unless cur_style == 'none'
    if el['iraOldComputedDisplayStyle']?
      el.style.display = el['iraOldComputedDisplayStyle']
    else
      el.style.display = iraUtils.defaultDisplay(el.tagName)

  # Use this to keep a copy of the old computed display style
  hideElement: (el) ->
    cur_style = getComputedStyle(el).getPropertyValue('display')
    return if cur_style == 'none'
    el['iraOldComputedDisplayStyle'] = cur_style
    el.style.display = 'none'

  # IE9 does not support classList
  addClass: (el, classList) ->
    return unless el?
    if typeof(classList) == 'string'
      classList = classList.split(' ')
    targetClassList = el.className.split(' ')
    for c in classList
      targetClassList.push(c) unless c in targetClassList
    el.className = targetClassList.join(' ')

  removeClass: (el, classList) ->
    return unless el?
    if typeof(classList) == 'string'
      classList = classList.split(' ')
    targetClassList = el.className.split(' ')
    for c in classList
      i = targetClassList.indexOf(c)
      targetClassList.splice(i, 1) unless i == -1
    el.className = targetClassList.join(' ')

  getFormGroupParent: (el) ->
    return null unless el?
    parent = el.parentElement
    while parent?
      cls = parent.className.split(' ')
      continue unless cls.length > 0
      return parent if cls.indexOf('ira-form-group') != -1
      return parent if cls.indexOf('form-group') != -1
      return parent if cls.indexOf('form-row') != -1
      parent = parent.parentElement
    null

  isAlreadyParsed: (el) ->
    # Use raw DOM element
    el = el[0] unless el['tagName']?
    return true unless el?
    return el.iraAlreadyParsed if el['iraAlreadyParsed']?
    # Support old syntax
    return true if el.hasAttribute('data-ira-already-parsed')
    false

  # Return true if el is a form element, which may fire an input event
  # i.e. input, textarea and select
  isInputElement: (el) ->
    if el.tagName == 'INPUT' and  iraUtils.isPresent(el.type)
      return false if el.type in ['submit', 'button', 'radio', 'checkbox']
      return true
    el.tagName in ['SELECT', 'TEXTAREA']

  # Return true if el is a form element, which may fire a click event
  # i.e. input type checkbox and radio
  isCheckableElement: (el) ->
    return false unless el.tagName == 'INPUT'
    return false unless el.type?
    return el.type in ['radio', 'checkbox']

  isDisabledElement: (el) ->
    return true if el.disabled
    return true if el['iraForceDisable']? and el['iraForceDisable'] == true
    return true if el.name.slice(0, 5) == 'fake_'
    false

  isFormElement: (el, check_if_disabled = true) ->
    return false unless el['name']? and el.name != ''
    return false if check_if_disabled and iraUtils.isDisabledElement(el)
    return iraUtils.isInputElement(el) or iraUtils.isCheckableElement(el)

  isPresent: (item) ->
    # Return false if item is null or undefined
    return false unless item?
    # Return true if item is a string and isn't ''
    return (item != '') if typeof(item) == 'string'
    # Return false if item is an empty array
    return (item.length > 0) if Array.isArray(item)
    return (Object.keys(item).length > 0) if typeof(item) == 'object'
    # In all other case, consider item truthy
    true

  valueOf: (el) ->
    if el.hasAttribute('data-ira-force-value')
      return el.getAttribute('data-ira-force-value')
    if iraUtils.hasCKEditor(el)
      return iraEditorsList[el.id].getData()
    if el.tagName == 'SELECT' and el.multiple
      values = []
      for opts in el.querySelectorAll('option')
        values.push(opts.value) if opts.selected
      return values
    if iraUtils.isCheckableElement(el)
      return null unless el.form?
      values = []
      for opts in el.form.querySelectorAll("[name='#{el.name}']")
        if opts.checked
          ov = opts.value
          ov = 'true' if ov == 'on'
          values.push(ov)
      if el.type == 'checkbox'
        if values.length < 2 and !el.name.endsWith('[]')
          return values[0]
        return values
      # For radio button, avoid to return undefined if no input is
      # checked
      return null if values.length == 0
      # Return the first found value for radio.
      return values[0]
    # For all other input type (even textarea), the value is sufficient
    val = el.value
    return val unless el.hasAttribute('data-ira-value-is-json')
    val = '{}' unless iraUtils.isPresent(val)
    try
      val = JSON.parse(val)
    catch error
      console.error 'Bad JSON syntax for', el
    val

  stopEvent: (event) ->
    event.preventDefault()
    event.stopPropagation()

  fieldIdFromFieldName: (name) ->
    name = name.replace(/\./g, '_')
    return name if name.indexOf('[') == -1
    # here we will create specific variable names for array input
    # name.
    # i.e. transform toto[tata][0] to toto_tata_0
    # Warning, if you have a field named toto[] and another named
    # toto, the later may be replaced by the first.
    other_name = name.replace(/[\[\]]/g, '_')
    other_name = other_name.replace(/_{2,}/g, '_')
    other_name.replace(/_+$/, '')

  hasCKEditor: (elem) ->
    return false unless elem.id?
    return false unless elem.hasAttribute('data-ira-widget')
    return false unless window['iraEditorsList']?
    elem.id of iraEditorsList

  initAction: (elem, uuid) ->
    # Form are managed elsewhere. Do not excecute this method for form
    # elements
    return true if elem.tagName == 'FORM'
    return true if elem.hasAttribute('data-ira-ignore')
    return true if iraUtils.isAlreadyParsed(elem)
    elem['iraScopeUuid'] = uuid
    elem.addEventListener 'click', (event) ->
      iraUtils.stopEvent(event)
      return false unless event.target?
      target = event.target
      while target?
        break if target.hasAttribute('data-ira-action')
        target = target.parentElement
      return false unless target?
      go_please = true
      if target.hasAttribute('data-ira-confirm')
        go_please = window.confirm(target.getAttribute('data-ira-confirm'))
      return false unless go_please
      scope = null
      if target['iraScopeUuid']?
        scope = iraApp.getScope(target.iraScopeUuid)
      scope.exec(elem: target)
    elem['iraAlreadyParsed'] = true

  getQueryData: (var_chain) ->
    query_data = {}
    for stuff in var_chain.split('&')
      try
        [key, value] = stuff.split '=', 2
        value = decodeURIComponent(value)
      catch error
        console.error 'Error in getQueryData', error, var_chain
        value = null
      if value in ['true', 'TRUE']
        value = true
      else if value in ['false', 'FALSE']
        value = false
      else if value in ['nil', 'null', 'undefined', 'NULL', 'UNDEFINED']
        value = null
      if key.endsWith('[]')
        key = key[0..-3]
        query_data[key] = [] unless query_data[key]?
        query_data[key].push value
      else
        query_data[key] = value
    query_data

  # See iraApp.filterScope to have details about the parameters
  scopeMatcher: (scope, query) ->
    if typeof(query) == 'function'
      try
        return true if query.call(scope)
      return false
    return false if !query['on']? or !query['value']?
    authorised_filters = ['uuid', 'parent_id', 'root_id',
                          'scope_type', 'entity']
    matcher = switch query['on']
      when 'uuid' then (scope, val) ->
        scope.id == val
      when 'parent_id' then (scope, val) ->
        scope.root?.parentElement?.id == val
      when 'root_id' then (scope, val) ->
        scope.root?.id == val
      when 'scope_type' then (scope, val) ->
        scope.type == val
      when 'entity' then (scope, val, entity_name) ->
        entity_name of scope.context and
        scope.context[entity_name]?['id'] == val
      else ->
        false
    matcher(scope, query['value'], query['entity_name'])

  currentRootUrl: ->
    [document.location.protocol, document.location.host].join('//')

  fullUrl: (url) ->
    return null if !url or url == '' or url[0] == '#'
    if url.slice(0, 7) in ['https:/', 'http://']
      return url
    if url[0] == '/'
      return "#{iraUtils.currentRootUrl()}#{url}"
    dirname = document.location.pathname.replace(/\/[^\/]*$/, '')
    "#{iraUtils.currentRootUrl()}#{dirname}/#{url}"

  entityUrl: (model, id) ->
    basic_uri = iraApp.main_scope.get("#{model}_base_url")
    unless iraUtils.isPresent(basic_uri)
      basic_uri = iraUtils.currentRootUrl() + document.location.pathname
    basic_uri + '?ira-action=' + model + '/view/' + id

  shareUrl: (model, id, encode_target = true) ->
    return '' if !iraUtils.isPresent(model) or !iraUtils.isPresent(id)
    link_target = iraUtils.entityUrl(model, id)
    link_target = encodeURIComponent(link_target) if encode_target
    share_params = [
      'object=' + id,
      'lang=' + iraApp.main_scope.get('lang'),
      'redirectUrl=' + link_target
    ]
    use_proxy = iraApp.main_scope.get('use_proxy_api')
    if use_proxy == true
      # /social is a special Cloudfront endpoint, which allows to pass
      # User-Agent to the API
      root_url = iraUtils.currentRootUrl() + '/social'
    else
      root_url = iraApp.config['api_url']
    root_url + '/share?' + share_params.join('&')


module.exports = iraUtils
