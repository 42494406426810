# IE and safari fix
require('promise-polyfill/dist/polyfill')
require('whatwg-fetch')

# Internal requirements
iraScope = require('./scope.coffee')
iraUtils = require('./utils.coffee')

# Main App class, responsible for parsing the current webpage and manage
# the scopes constellation.
#
# @include i18nHelper
# @include sessionHelper
#
class iraApp
  constructor: ->
    # Exist only to quickly add iraApp to the window global object
    @_parsing_complete = false
    @_first_rendering_complete = false

    @xhr_sig_store = []
    @scopes_store = {}

  init: ->
    globals =
      currency: 'EUR'
      lang: 'fr_FR'
      isLoggedIn: ->
        return false unless @['user-token-expire']?
        now = Math.floor(Date.now() / 1000)
        return false if @['user-token-expire'] < now
        @['user-token']?
      current_lang: ->
        return '' unless window['iraApp']?
        window['iraApp']._locale_name()
      noStorageAPI: -> window['iraNoStorageAPI']

    @main_scope = new iraScope(null, null, globals)
    @main_scope.type = 'main_scope'

    cloudinary_prefix = 'https://res.cloudinary.com/iraiser/image/'
    @config =
      api: 2
      filters:
        cloudinary: (t, opts) ->
          return '' unless iraUtils.isPresent(t)
          upload_url = "#{cloudinary_prefix}upload/"
          if t.startsWith(upload_url)
            return t unless opts[0]?
            file = t.substring(upload_url.length - 1)
          else if t.match(/^https?:\/\//)?
            fetch_uri = "#{cloudinary_prefix}fetch"
            fetch_uri = "#{fetch_uri}/#{opts[0]}" if opts[0]?
            return "#{fetch_uri}/#{t}"
          else
            file = '/' + String(t)
          upload_url + opts[0] + file
        'else': (t, opts) ->
          return t if t? or !opts[0]?
          _fal = window['iraApp'].keyOrAlias opts[0]
          window['iraApp'].main_scope._get_deep(_fal, @)[0]
        'default': (t, opts) ->
          return t if t? or !opts[0]?
          opts[0]
        url_encode: (t, opts) ->
          return '' if !t? or t == ''
          encodeURIComponent(String(t))
    if iraConfig?
      for k, v of iraConfig
        if k == 'variables'
          @main_scope.set(key, value, true) for key, value of v
          continue
        if k == 'filters'
          @config.filters[key] = value for key, value of v
          continue
        v = v.slice(0, -1) if k == 'api_url' and v.slice(-1) == '/'
        @config[k] = v

    @_fire_event 'iraAppConfigReady'

    @_parse_metas()

    return if @testEnv()

    console.log '''\n _         _____
|_|___ __ |  _  |___ ___
| |  _|_.`|     | . | . |
|_|_| |___|__|__|  _|  _|
                |_| |_|


iraApp v2.1.7 build 20190930130412
'''

    @_load_data_from_session()
    @_check_user_session()

    if !@config['config_url']? or @config['config_url'] == ''
      console.log 'No config_url set, now use default symbols table.'
      return @start(require('./data/config.json'))

    fetch(@config['config_url']).then (response) ->
      return response.json() if response.ok
      null
    .then (config) ->
      throw new Error('IRA_NO_CONFIG') unless config?
      window['iraApp'].start(config)
    .catch (error) ->
      err = error['message'] ? error
      if err == 'IRA_NO_CONFIG'
        console.warn 'Default config is used. Fetch error was', err
        window['iraApp'].start(require('./data/config.json'))
      else
        console.error err

  # Actually launch iraApp.js
  #
  start: (symbols = {}) ->
    # Build the allowed_objects element list
    @allowed_objects = Object.keys symbols['models']
    if @config['allowed_objects']?
      @allowed_objects = @allowed_objects.concat @config.allowed_objects
    @config['symbols'] = symbols
    defs = @config.symbols['defaults'] ? {}
    @main_scope.set 'currency', defs['currency'] ? 'EUR', true
    @main_scope.set 'lang', @_extract_default_locale(defs['lang']), true
    @main_scope.set 'prefix', defs['prefix'], true
    @_extract_constraints()

    @_fetch_from_uri_and_metas()

    title_tag = document.querySelector('title')
    @main_scope._extract_attr(
      title_tag.textContent, title_tag,
      'doc-title', 'DocTitle') if title_tag?
    for elem in document.querySelectorAll('.ira-app')
      s = new iraScope(elem, @main_scope.uuid)
      @main_scope.subscopes[s.uuid] = s
      s.render()

    return if @testEnv()

    document.addEventListener 'iraRenderingComplete', (event) ->
      # First, inform the world about the overall readyness of iraApp
      window['iraApp']._check_and_fire_readiness()
      # Then parse new form as they appear to watch its fields
      if window['iraApp'].loadingComplete()
        window['iraApp']._parse_new_fields(event['iraScopeUuid'])

    window.addEventListener 'hashchange', ->
      window['iraApp'].main_scope.exec(action: document.location.hash)

    @_parsing_complete = true
    @_fire_event 'iraParsingComplete'
    @_check_and_fire_readiness()

  # Test if the string passed in parameters is a valid model name.
  #
  # @param stuff [String] the potential model name to test
  # @return [Boolean]
  isAllowedObject: (stuff) ->
    # We use indexOf and not the in coffeescript operator to speed up
    # the search in the array.
    return @allowed_objects.indexOf(stuff) != -1

  # Test whether the code is actually running in test mode.
  #
  # To run the code in test mode, you have add the key `environment`
  # with a value of "*development*" in the `iraConfig` object declared
  # before loading the iraApp.js file.
  #
  # @return [Boolean]
  testEnv: ->
    @config['environment'] and @config['environment'] == 'test'

  # Return the canonical form of the given `key`.
  #
  # This canonical form may be equal to `key` if no alias is declared
  # for it.
  #
  # @param key [String] the key to expand
  # @return [String] the canonical form of the key
  keyOrAlias: (key) ->
    return key if !@config['symbols']? or !@config.symbols['aliases']?
    if @config.symbols['aliases'][key]?
      return @config.symbols['aliases'][key]
    key

  # Return the relationship name between `child` and `parent`.
  #
  # This method return `null` if no relationship are found between this
  # two model names.
  #
  # The relationship name is either `reference`, or `parent`, or
  #`origin`.
  #
  # @param child [String] the child model name
  # @param parent [String] the parent model name
  # @return [String] the relationship name or `null`.
  parentingRelationshipName: (child, parent) ->
    return null unless @config?.symbols?.models
    return null unless child of @config.symbols.models
    return null unless @config.symbols.models[child]['_meta']?
    for r, v of @config.symbols.models[child]['_meta']
      return r[0..-2] if parent in v
    return null

  # Filter the `scopes_store` to return a subset of them.
  #
  # The filtering is done either with a function or a query object. If a
  # function is given as second parameter, it will be called with a
  # scope as parameter and is expected to return a boolean.
  #
  # If a query object is given, it is expected to be built with the
  # following syntax:
  #
  # The `on` inner attribute of the query object must be given and
  # be one of `uuid`, `parent_id`, `root_id`, `scope_type`, `entity`.
  #
  # The `value` inner attribute must be given and contain the actual
  # value to match.
  #
  # If you use the `entity` filtering mode, the `entity_name` must also
  # be given and the `value` attribute will contains the entity id to
  # find.
  #
  # @param query [Object or Function] filter definition
  # @option query on [String] filter on this type
  # @option query value [String] the value to filter on
  # @option query entity_name [String] contact, participant…
  # @param store [Array] Alternate scopes list to filter
  # @return [Array<iraScope>]
  filterScopes: (query = {}, store = null) ->
    answer = []
    if Array.isArray(store)
      for s in store
        answer.push(s) if iraUtils.scopeMatcher(s, query)
    else
      for s in Object.values(@scopes_store)
        answer.push(s) if iraUtils.scopeMatcher(s, query)
    answer

  # Get the scope, which uuid is given in parameter.
  #
  # @param uuid [String] the uuid of the scope to retrieve
  # @return [iraScope] the corresponding scope or null
  getScope: (uuid) ->
    @scopes_store[uuid]

  # Get the scope, only if its uuid match the given conditions.
  #
  # This function return a scope, only if it matches the given uuid and
  # the given conditions. Internally, a try/catch mechanism allow you to
  # quickly write conditions.
  #
  # Condition parameter must be a callable, which will receive the scope
  # matching the given uuid as `this` argument. This callable **must**
  # return a boolean.
  #
  # @param uuid [String] the uuid of the scope to retrieve
  # @param condition [Function] a function to further test the scope
  # @return [iraScope] the corresponding scope or null
  getScopeWhen: (uuid, condition) ->
    s = @scopes_store[uuid]
    return null unless s?
    return null if !condition?
    return s if iraUtils.scopeMatcher(s, condition)
    null

  # Execute a given API requests on the main scope context.
  #
  # This is a shortcut for the iraApp.main_scope.exec(action:...) call.
  #
  # @param query [Object]
  # @param query entity [String] the entity type to retrieve.
  # @param query params [String] query string to be submitted to the API.
  # @param query uuid [String] the entity uuid to fetch. If not given, a
  #                            search is made.
  exec: (query) ->
    return false unless query['entity']?
    if query['uuid']?
      act = "##{query['entity']}/view/#{query['uuid']}"
      return window['iraApp'].main_scope.exec(action: act)
    # TODO
    false

  # Test wether initial loading is completed
  #
  # @return [Boolean]
  loadingComplete: ->
    return false unless @_parsing_complete
    @xhr_sig_store.length == 0

iraUtils.include iraApp, require('./main/dom.coffee')
iraUtils.include iraApp, require('./main/i18n.coffee')
iraUtils.include iraApp, require('./main/session.coffee')
iraUtils.include iraApp, require('./main/check_fields.coffee')
iraUtils.include iraApp, require('./main/plugin.coffee')
module.exports = iraApp

# @nodoc
String::titleize = ->
  title = []
  for word in @split(' ')
    continue if !word? or word == ''
    title.push (word[0].toUpperCase() + word[1..-1].toLowerCase())
  title.join ' '

# @nodoc
String::singularize = ->
  return @.toString() if @.charAt(@length - 1) != 's'
  @substr(0, @length - 1)

# @nodoc
String::pluralize = ->
  return @.toString() if @.charAt(@length - 1) == 's'
  @ + 's'

# @nodoc
String::rpad = (padding, padwith = ' ') ->
  padstr = ''
  padstr += padwith for [0...padding]
  (@ + padstr).slice 0, padding

# @nodoc
String::lpad = (padding, padwith = ' ') ->
  padstr = ''
  padstr += padwith for [0...padding]
  (padstr + @).slice -padding

# Remove duplicate elements from an array of string or number
#
# This DOES NOT works with array of objects
#
# @return [Array] the cleaned copy of the array
Array::uniq = ->
  @[i] for i in [0..@.length] when @.indexOf(@[i]) == i

# Remove a value from an array as an atomic operation
#
# @return [Array] the deleted elements
Array::remove = (value) ->
  idx = @.indexOf(value)
  return [] if idx == -1
  ret = []
  while idx != -1
    ret = ret.concat @.splice(idx, 1)
    idx = @.indexOf(value)
  ret

Array::filterScopes = (query) ->
  return [] unless 'iraApp' of window
  window['iraApp'].filterScopes(query, @)

# Reset a regexp before matching a text with it.
#
# This allow us to declare sooner a regexp, and reuse it again and again
# without losing compile time.
#
# @param str [String] the string against which to match the regexp.
# @return [Array] the matched data, or null.
RegExp::resetAndExec = (str) ->
  @lastIndex = 0
  @exec str

# Reset a regexp before using it to replace some string content.
#
# This allow us to declare sooner a regexp, and reuse it again and again
# without losing compile time.
#
# @param regexp [RegExp] the pattern used to match substring.
# @param newSubstr [String] the string, which will replace any matched str.
# @return [String] a new string, after replacement.
String::resetAndReplace = (regexp, newSubstr) ->
  regexp.lastIndex = 0
  @replace regexp, newSubstr

# Fix for IE
unless Object.values?
  Object['values'] = (target) ->
    v for _, v of target

iraAppAddPlugin = (plugin) ->
  document.addEventListener 'iraAppConfigReady', ->
    iraApp.addPlugin plugin

iraAppAddFilter = (filter) ->
  document.addEventListener 'iraAppConfigReady', ->
    iraApp.addFilter filter

# In some weird case (Safari in private browsing mode, maybe others?)
# localStorage and sessionStorage quota are at 0. It will break
# multipage workflow and we may display a custom message, or do nothing
# for single page workflow as it as little or no influence on the
# process itself.
window['iraNoStorageAPI'] = true
if typeof(localStorage) == 'object'
  try
    localStorage.setItem 'ira-test-ls', 1
    localStorage.removeItem 'ira-test-ls'
    # all is good, yeepee
    window['iraNoStorageAPI'] = false
  catch
    console.warn 'Storage API is not available in this browser'


# Always load iraApp
window['iraApp'] = new iraApp()
document.addEventListener 'DOMContentLoaded', ->
  window['iraApp'].init() if document.querySelector('.ira-app')?
