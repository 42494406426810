iraUtils = require('../utils.coffee')
iraModel = require('../model.coffee')
iraMetaController = require('./meta_controller.coffee')


class iraCloudinaryController extends iraMetaController
  constructor: ->
    super('cloudinary')

  open: (args) ->
    return false unless args.id?
    real_field = document.getElementById args.id
    return false unless real_field
    unless cloudinary?
      console.error 'No cloudinary module found.'

    # See more at https://cloudinary.com/documentation/upload_widget#upload_widget_options
    # First, we set generic options, which may be overwritten by the
    # design.
    cloudinary_opts =
      language: iraApp.main_scope.get('lang').split('_', 2)[0]
      sources: ['local', 'camera', 'facebook', 'instagram']
      defaultSource: 'local'
      autoMinimize: true

    if real_field.hasAttribute 'multiple'
      cloudinary_opts['multiple'] = true
    else
      cloudinary_opts['cropping'] = true
      cloudinary_opts['croppingShowBackButton'] = true
      if real_field.hasAttribute 'data-ira-ratio'
        ratio = parseFloat(real_field.getAttribute('data-ira-ratio'))
        unless isNaN(ratio)
          cloudinary_opts['croppingAspectRatio'] = ratio

    # Now we can overwrite them with the design options, if available.
    if window['cloudinary_opts']?
      cloudinary_opts[k] = v for k, v of window['cloudinary_opts']
    loc_custom_opts = "#{args.id}_cloudinary_opts"
    if window[loc_custom_opts]?
      cloudinary_opts[k] = v for k, v of window[loc_custom_opts]
    if real_field.hasAttribute('data-ira-cloudinary-options') and \
       window[real_field['data-ira-cloudinary-options']]?
      for k, v of window[real_field['data-ira-cloudinary-options']]
        cloudinary_opts[k] = v

    # Then we set critical options
    cloudinary_opts['cloudName'] = 'iraiser'
    cloudinary_opts['api_key'] = '185237422612789'
    cloudinary_opts['uploadSignature'] = (callback, params_to_sign) ->
      cc = new iraCloudinaryController()
      cc._request
        url: 'https://cloudinary-sign.iraiser.eu/'
        method: 'POST'
        body: JSON.stringify(data: params_to_sign)
        context:
          callback: callback
        success: (data) ->
          return false unless 'valid' of data
          return false unless data['valid'] == true
          @callback(data['signature'])

    prefix = iraApp.main_scope.get('prefix')
    cloudinary_opts['folder'] = prefix if prefix?
    tags = cloudinary_opts['tags'] ? []
    tags.push(t) for t in args.tail
    if iraApp.main_scope.get('isLoggedIn')
      uid = iraApp.main_scope.get('contact.id')
      tags.push("user-#{uid}")
    cloudinary_opts['tags'] = tags unless tags.length == 0

    cloudinary_upload_widget = cloudinary.openUploadWidget \
      cloudinary_opts, (error, result) ->
        if error?
          console.error error
          return false
        unless result.event?
          console.error 'No event type in Cloudinary answer'
          return false
        # Skip intermediate answers
        return true unless result.event == 'success'
        unless result.info?
          console.error 'No info object in Cloudinary answer'
          return false
        p = result.info
        real_field.setAttribute \
          'data-ira-preview-title', p.original_filename
        scope = iraApp.getScope(real_field['iraScopeUuid'])
        scope.set(real_field.name, p.secure_url) if scope?
        cloudinary_upload_widget.close()


module.exports = iraCloudinaryController
