iraUtils = require('./utils.coffee')

class iraActionRunner
  constructor: (@element, @action_raw = null, @scope = null) ->
    @action =
      target: ''
      controller: null
      method: ''
      arguments:
        tail: []
        from_element: @element
      redirect: false
      exists: false
      affect_only: false
    @action.arguments['from_scope'] = @scope.uuid if @scope?

    @app_methods = [
      'gotopage', 'invite', 'next', 'previous', 'search', 'submit'
    ]

    if @action_raw?
      @_split_action_raw()
      return

    return unless @element?

    if @element.tagName == 'FORM' and !@element.hasAttribute('data-ira-action')
      # Form default action is submit
      @element.setAttribute('data-ira-action', 'submit')

    @action_raw = @element.getAttribute('data-ira-action')
    if @action_raw in @app_methods
      # Shortcut for some quick action
      @action_raw = "#app/#{@action_raw}"
    else if @action_raw in ['signup', 'login', 'logout']
      # Shortcut for some user related actions
      @action_raw = "#contact/#{@action_raw}"
    else if @action_raw.indexOf('#') == -1
      @action_raw = '#' + @action_raw

    @_split_action_raw()
    return unless @action.exists

    if @element.tagName == 'FORM'
      @setRedirectUrl(@element.getAttribute('action'))
    else if @element.tagName == 'A'
      @setRedirectUrl(@element.getAttribute('href'))

  setRedirectUrl: (url) ->
    url = iraUtils.fullUrl(url)
    return false unless url?
    @action.redirect = true
    @action.target = url

  setArgument: (name, value) ->
    @action.arguments[name] = value

  _set_context_vars: (var_chain) ->
    try
      query_data = iraUtils.getQueryData(decodeURIComponent(var_chain))
    catch
      console.error 'Error in _set_context_vars', error, var_chain
      query_data = {}
    for key, value of query_data
      if key of iraApp.main_scope.context
        iraApp.main_scope.set key, value
        iraApp.main_scope.notify(['current_lang']) if key == 'lang'
      else
        @scope.set(key, value) if @scope?
    true

  _set_only_context_vars: (var_chain) ->
    result = @_set_context_vars var_chain
    @action.affect_only = result
    @action.exists = result
    # Reset controller and method
    @action.controller = null
    @action.method = ''
    return @action

  _split_action_raw: ->
    return @action unless @action_raw?
    [back_url, params] = @action_raw.split '#', 2
    return @action unless params?
    @setRedirectUrl(back_url)

    if params.indexOf('/') == -1
      return @_set_only_context_vars(params)

    method_split = params.split('/')
    controller_name = method_split[0]
    # We keep compatibility on user vs. contact naming because it's a
    # bit more easy to understand user/login or user/logout than
    # contact/login.
    controller_name = 'contact' if controller_name == 'user'
    if controller_name in @app_methods
      @action.method = controller_name
      controller_name = 'app'
      method_split = method_split.slice(1)
    else
      @action.method = method_split[1]
      method_split = method_split.slice(2)
    # Never try to call private methods!
    @action.method = '' if @action.method[0] == '_'

    unless iraUtils.isPresent(@action.method)
      return @action

    if controller_name == 'contact'
      _cont = require('./controllers/contacts.coffee')
      @action.controller = new _cont()
    else if controller_name == 'cloudinary'
      _cont = require('./controllers/cloudinary.coffee')
      @action.controller = new _cont()
    else
      _cont = require('./controllers/entities.coffee')
      @action.controller = new _cont(controller_name)
    return @action unless @action.controller?

    if @action.controller.answersToMethod @action.method
      @action.exists = true
    else
      @action.controller = null
      @action.method = ''
      return @action

    if method_split[0]? and method_split[0].indexOf('=') == -1
      @action.arguments['id'] = method_split[0]
      method_split = method_split.slice(1)
    for arg in method_split
      if arg.indexOf('=') == -1
        @action.arguments.tail.push arg
      else
        @_set_context_vars arg

    @action

  run: ->
    return false unless @action.exists
    return true if @action.affect_only

    if @scope? and @action.method == 'view' and
       @action.controller.api_end_point != 'cloudinary'
      @scope.remove(@action.controller.api_end_point)

    if @action.redirect
      iraApp.main_scope.set 'redirect_url', @action.target, true
      iraApp.main_scope.set 'successful_url', @action.target, true
      iraApp.main_scope.set 'failed_url', document.location.href, true
    else
      iraApp.main_scope.remove 'redirect_url', true

    @action.controller[@action.method](@action.arguments)


module.exports = iraActionRunner
