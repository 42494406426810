iraUtils = require('../utils.coffee')

iraSubmitForm = ->
  return false unless @iraScopeUuid
  scope = iraApp.getScope(@iraScopeUuid)
  return false unless scope?

  # add a class to the form indicating that the form is currently
  # being processed
  iraUtils.addClass(@, 'ira-processing')

  # desactivate all submit buttons
  for sub in @querySelectorAll('input[type=submit], button[type=submit]')
    sub.disabled = true
    sub.setAttribute('disabled', 'disabled')

  # remove all error fields
  scope._remove_errors()

  # Now we can call hooks if they exists
  iraApp.runHooks('preSubmit', scope, @)

  # run the form action
  scope.exec(elem: @)

iraCallSubmitForm = (event) ->
  return false if !event.target or event.target.tagName != 'FORM'
  iraUtils.stopEvent(event)
  event.target.iraSubmit()

# Handle all forms related features
#
# @mixin
#
formHelper =
  # This method will set a value in the current scope from the HTML
  # Element passed in parameter current value.
  #
  # This method return the same result as the underlying
  # {contextHelper~set set} method.
  #
  # @param elem [HTMLElement] The element from which a value must be set
  # @param set_only [Boolean] Wether the set action must notify the app
  # @return [mixed]
  setFromElem: (elem, set_only = false, do_check = true) ->
    new_val = iraUtils.valueOf(elem)
    new_val = new_val.trim() if elem.name == 'contact.code'
    @_handle_field_decoration(elem, new_val) if do_check
    @set(elem.name, new_val, set_only)

  # @private
  _handle_field_decoration: (elem, value) ->
    # Find this element parent group
    parent_group = iraUtils.getFormGroupParent(elem)

    c = iraApp.check_field(elem.name, value)
    if c[0] == false and c[1] not in ['UNKNOWN', 'READ_ONLY']
      # This field does not respect its constraints. Display the error
      # to the end user.
      # For now we ignore completely the unknown fields (which are not
      # configured in config.json)
      return false unless parent_group?
      iraUtils.addClass(parent_group, 'has-error')
      help_span = parent_group.querySelector('.ira-form-input-error')
      return false unless help_span?
      errMsgs = iraApp.config?.symbols?.defaults?.translations
      return false unless errMsgs?
      errMsgs = errMsgs[iraApp.main_scope.get('lang')]
      err_lo = c[1].toLowerCase()
      # Avoid some known-to-be untranslated messages
      err_lo = 'value_error' if err_lo == 'type_error'
      err_lo = 'max_length' if err_lo == 'length_error'
      help_span.textContent = errMsgs[err_lo] if errMsgs?[err_lo]?
      return false

    return true unless parent_group?
    # Remove error decorations if any
    iraUtils.removeClass(parent_group, 'has-error')
    help_span = parent_group.querySelector('.ira-form-input-error')
    help_span.textContent = '' if help_span?
    true

  # @private
  _remove_errors: ->
    # remove all general error messages
    for errdiv in @root.querySelectorAll('.ira-form-general-error')
      errdiv.textContent = ''
      iraUtils.hideElement(errdiv)
    # remove the form status
    iraUtils.removeClass(@root, ['ira-error', 'ira-success'])

  # @private
  _update_form_elements: ->
    # Weird IE bug
    return unless @config['form_elements']?
    for el in @config['form_elements']
      # real file inputs are hidden or disabled, thus we have to manage
      # them as sooner as possible
      if el.type? and el.type == 'file'
        @updateFilePreview el
        continue

      continue if iraUtils.isDisabledElement(el)

      if el.hasAttribute('autocomplete')
        if el.getAttribute('autocomplete') == 'none'
          # Non standard value. Changing it
          el.setAttribute('autocomplete', 'off')
          el.autocomplete = 'off'
        continue if el.autocomplete == 'off'

      if el.type == 'date'
        today = new Date().toISOString()[0..9]
        for attr in ['min', 'max']
          if el.getAttribute(attr) == 'today'
            el.setAttribute(attr, today)

      loc_val = @get(el.name)

      if el.getAttribute('data-ira-widget') == 'cloudinary'
        el.value = loc_val if el.value != loc_val
        @updateFilePreview el, loc_val
        continue

      if el.tagName == 'SELECT'
        for opts in el.options
          if (Array.isArray(loc_val) and opts.value in loc_val) or
             loc_val == opts.value
            opts.setAttribute 'selected', 'selected'
            opts.selected = true
        continue

      if iraUtils.isCheckableElement(el)
        if (Array.isArray(loc_val) and el.value in loc_val) or
           loc_val == el.value
          el.setAttribute 'checked', 'checked'
          el.checked = true
        continue

      if el.hasAttribute('data-ira-value-is-json')
        loc_val = JSON.stringify(loc_val)

      # If loc_val is empty the form field will be emptied
      el.value = loc_val ? ''

  # Return the current form fields values.
  #
  # The return value can be a string (default behavior), when the scoped
  # form field values are stringified, or an object.
  #
  # @param opts [Object] optional options
  # @options opts stringify [Boolean] wether the return value must be stringified
  # @options opts with_defaults [Boolean] include default values (`lang`…)
  # @options opts save_ext [Boolean] save external vars
  # @return [mixed]
  formData: (opts = {}) ->
    _defaults =
      stringify: true
      with_defaults: true
      save_ext: false
    for k, v of _defaults
      opts[k] = v unless k of opts
    form_data = {}
    if 'includes' of @config
      for k in @config['includes']
        el_key = iraApp.keyOrAlias(k)
        # Get local value only for includes target!
        inc_value = @_get_deep(el_key, @context)
        form_data = @_set_deep(el_key, inc_value[0], form_data)
    if 'form_elements' of @config
      for el in @config['form_elements']
        continue if iraUtils.isDisabledElement(el)
        # force update
        new_val = @setFromElem el, true
        el_key = iraApp.keyOrAlias(el.name)
        form_data = @_set_deep(el_key, new_val, form_data)
    if opts.with_defaults
      unless iraUtils.isPresent(form_data['currency'])
        form_data['currency'] = iraApp.main_scope.get 'currency'
      unless iraUtils.isPresent(form_data['lang'])
        form_data['lang'] = iraApp.main_scope.get 'lang'
      for k in ['redirect_url', 'successful_url', 'failed_url']
        lv = iraApp.main_scope.get k
        form_data[k] = lv if lv and !form_data[k]?
    if opts.save_ext
      ext_name = @root.querySelector('input[name="ira:save-after-submit"]')
      if ext_name? and iraUtils.isPresent(ext_name.value)
        for valk in ext_name.value.split(',')
          valkc = valk.trim()
          valk_val = @_get_deep(valkc, @context)[0]
          valk_val = valk_val.toString() if valk_val?
          form_data = @_set_deep(valkc, valk_val, form_data)
    return form_data unless opts.stringify
    JSON.stringify(form_data)

  # Return all the names (tags) of the current form.
  #
  # @return [Array<String>] all the fields names
  formElementNames: ->
    return [] unless 'form_elements' of @config
    n = []
    for el in @config['form_elements']
      if iraUtils.valueOf(el) != '' and el.name not in n
        n.push(el.name)
    n

  # Return all the relevant names (tags) of the current form.
  #
  # This includes the previously set tags in case of a multi-page form.
  #
  # @return [Array<String>] all the fields names
  boundaries: ->
    b = @formElementNames()
    return b unless 'includes' of @config
    return b unless Array.isArray(@config['includes'])
    b.concat(@config['includes']).uniq()

  # Parse form elements.
  #
  # For each element of the scope's form, it initialize specific
  # behavior (_i10t fields, tinymce…) and add eventListener to react to
  # value changes.
  parseFormElements: ->
    for el in @root.elements
      continue if el.hasAttribute('data-ira-ignore')
      continue if iraUtils.isAlreadyParsed(el)
      continue unless iraUtils.isFormElement(el)

      el['iraScopeUuid'] = @uuid

      # We found the special include tag
      if el.name == 'ira:include'
        target_name = el.value
        data = iraApp.securelyGetDataFromSession()
        if data?.scope_boundaries?.name == target_name
          @config['includes'] = data.scope_boundaries.fields
          for k in @config.includes
            @set(k, @_get_deep(k, data)[0], true, false)
        continue

      if el.name == 'ira:save-after-submit'
        # For now, do nothing
        continue

      unless iraUtils.isPresent(@get(el.name))
        @setFromElem(el, true, false)

      @config['form_elements'].push(el)

      if el.getAttribute('data-ira-widget') == 'cloudinary'
        @_init_cloudinary_field(el)
        continue

      if el.type? and el.type == 'file'
        @_init_file_input(el)
        continue

      if CKEDITOR? and el.tagName == 'TEXTAREA' and el.id? and
         el.getAttribute('data-ira-widget') == 'html_editor'
        window['iraEditorsList'] = {} unless window['iraEditorsList']?
        window.iraEditorsList[el.id] = CKEDITOR.replace \
          el, {
            language: @get('lang'),
            toolbar: [
              { name: 'clipboard', items: [
                'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord',
                '-', 'Undo', 'Redo'
              ] },
              '/',
              { name: 'basicstyles', items: [
                'Bold', 'Italic', 'Underline', 'Strike', '-',
                'CopyFormatting', 'RemoveFormat'
              ] },
              { name: 'paragraph', items: [
                'NumberedList', 'BulletedList', '-',
                'Outdent', 'Indent', '-', 'Blockquote', '-',
                'JustifyLeft', 'JustifyCenter', 'JustifyRight',
                'JustifyBlock'
              ] },
              { name: 'links', items: [ 'Link', 'Unlink'] },
              { name: 'insert', items: [ 'Image', 'Table' ] },
            ]
          }
        continue

      if iraUtils.isCheckableElement(el) or el.tagName == 'SELECT' or
         (el.tagName == 'INPUT' and el.type == 'date')
        el.addEventListener 'change', @onChange, false
        el['iraAlreadyParsed'] = true
        continue

      # The following event detection is based on Tony Bourdier's
      # knowledge on payment app
      el['typingTimer'] = 0
      callback = (event) ->
        return false if event.type == 'keyup' and event.key == 'Tab'
        return false unless event.target?
        el = event.target
        clearTimeout el.typingTimer if el['typingTimer']?
        return false unless el['iraScopeUuid']?
        scope = iraApp.getScope(el.iraScopeUuid)
        return false unless scope?
        return scope.onChange(event) if event.type == 'change'
        el['typingTimer'] = setTimeout \
          scope.onChange.bind(el, event), 500
        true
      el.addEventListener 'keyup', callback, false
      # Some HTML5 elements may have popover widgets, which prevent the
      # end user to actually type anything in them
      el.addEventListener 'change', callback, false
    null

  # @private
  _parse_form: ->
    return true if @root.hasAttribute('data-ira-ignore')
    return true if iraUtils.isAlreadyParsed(@root)
    @config['form_elements'] = []
    @parseFormElements()
    @_remove_errors()
    @root['iraSubmit'] = iraSubmitForm
    @root.addEventListener 'submit', iraCallSubmitForm, false
    @root['iraAlreadyParsed'] = true

  # @private
  _parse_new_fields: ->
    # Do not block other listener
    parent = iraApp.getScope(@parentUuid)
    return true if !parent or parent.type != 'form'
    parent.parseFormElements()

  # Fired every time an input field value changes
  #
  # This method is bound to any iraApp watched HTML input element inside
  # a scope of type `form`.
  #
  # It is extremely important that this method stay visible from the
  # outside of the scope object. It will be used for example with the
  # change event of select2 or other external libs which may change data
  # on page.
  #
  # @param event [Event] the change or click event
  # @return [Boolean]
  onChange: (event) ->
    el = event.target
    return unless iraUtils.isFormElement(el)
    return false unless el['iraScopeUuid']?
    scope = iraApp.getScope(el.iraScopeUuid)
    return false unless scope?
    # Delegate all the checking and setting job to the setFromElem
    # method.
    # No fields should send a real `false` to the API, thus it's a safe
    # choice to check that the `setFromElem` method did not return this
    # value to know if something wrong happen or not.
    scope.setFromElem(el)
    # Now we can call hooks if they exists
    iraApp.runHooks('onChange', scope, el)


module.exports = formHelper
