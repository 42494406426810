iraUtils = require('../utils.coffee')

# Open an API to add iraApp plugins
#
# @mixin
#
pluginHelper =
  supportedHooks: ['preSubmit', 'onChange', 'onSuccess']

  addPlugin: (plugin) ->
    return false unless iraUtils.isPresent(plugin.name)
    @plugins ?= {}
    @plugins[plugin.name] = plugin
    if plugin.hooks?
      @config.hooks ?= {}
      for hname, hook of plugin.hooks
        continue unless hname in window['iraApp'].supportedHooks
        @config.hooks[hname] ?= []
        @config.hooks[hname].push plugin.name
    return true unless plugin.config?
    for k, v of plugin.config
      @config[k] = v
    true

  addFilter: (filter) ->
    return false unless iraUtils.isPresent(filter.name)
    return false unless iraUtils.isPresent(filter.filter)
    @config.filters ?= {}
    @config.filters[filter.name] = filter.filter
    true

  runHooks: (hook, scope, elem) ->
    return false unless @config?.hooks?[hook]
    return false unless Array.isArray(@config.hooks[hook])
    for f in @config.hooks[hook]
      if typeof(f) == 'function'
        f.call(elem, scope)
        continue
      continue unless typeof(f) == 'string'
      continue if !@plugins? or f not of @plugins
      pf = @plugins[f]?['hooks']?[hook]
      continue unless pf?
      if typeof(pf) == 'function'
        pf.call(elem, scope)
        continue
      continue unless Array.isArray(pf)
      for pff in pf
        pff.call(elem, scope)
    true


module.exports = pluginHelper
