CURRENCY_DATA = require('../data/currency.json')

AVAILABLE_LANGUAGES =
  fr_FR: 'Français'
  en_US: 'English (US)'
  en_GB: 'English'
  nl_NL: 'Nederlands'

DEFAULT_CURRENCY =
  fr_FR: 'EUR'
  en_US: 'USD'
  en_GB: 'GBP'
  nl_NL: 'EUR'

if !Number['formatMoney']?
  Number::formatMoney = (currency = 'EUR', with_decimal = false) ->
    n_str = (if with_decimal then @.toFixed(2) else @).toString()
    return n_str unless CURRENCY_DATA[currency]?
    cur = CURRENCY_DATA[currency]

    if window['iraApp']?
      cur_lang = iraApp.main_scope.get('lang')
      if cur['except']?[cur_lang]?
        for k, v of cur['except'][cur_lang]
          cur[k] = v

    result = if cur.symbol_first then cur.symbol else ''

    n_dat = n_str.split('.')
    int_part = n_dat[0]
    re = /(\d+)(\d{3})/
    while re.test(int_part)
      int_part = int_part.replace(re, "$1#{cur.thousands_separator}$2")
    result = result + int_part

    decimal_part = n_dat[1] ? ''
    if decimal_part != ''
      decimal_part = cur.decimal_mark + decimal_part.rpad(2, '0')
    result = result + decimal_part

    return result + cur.symbol unless cur.symbol_first
    result

# i18n specific helper methods
#
# @mixin
#
i18nHelper =
  # Give the correct BCP 47 (IETF) lang code from an ISO-639-1 one
  #
  # This function only works for some language… It return an array
  # containing the two components of the best BCP 47 lang code we can
  # imagine from the given array
  #
  # @param iso639_code [Array<String>] the ISO-639-1 lang code to convert
  # @return [Array<String>] something, which looks like an BCP 47 lang code
  _iso639_to_bcp47: (iso639_code) ->
    return ['en', 'US'] if iso639_code[0] == 'en'
    return ['zh', 'CN'] if iso639_code[0] == 'zh'
    return ['cs', 'CZ'] if iso639_code[0] == 'cs'
    iso639_code[1] = iso639_code[0]
    iso639_code

  # Extract default locale for the current webpage.
  #
  # This method look for various `lang` HTML attributes or `meta` tags
  # to determine the current page locale.
  #
  # If nothing is found, it tries to fallback on the config lang, or
  # *fr_FR* if nothing is found.
  #
  # @private
  #
  # @param conf_lang [String] locale found in the app configuration
  # @return [String] the locale code to use from now on
  #
  _extract_default_locale: (conf_lang = null) ->
    # Look for a declared locale
    default_locale = null
    for el in [document.querySelector('html'),
               document.querySelector('head'),
               document.querySelector('body'),
               document.querySelector('.ira-app')]
      continue unless el?
      if el.hasAttribute('xml:lang')
        default_locale = el.getAttribute('xml:lang')
        break
      if el.hasAttribute('lang')
        default_locale = el.getAttribute('lang')
        break

    if !default_locale? or default_locale == ''
      for el in [document.querySelector('meta[http-equiv="content-language"]'),
                 document.querySelector('meta[name="language"]'),
                 document.querySelector('meta[property="dc.language"]'),
                 document.querySelector('meta[property="og:locale"]')]
        continue unless el?
        continue unless el.hasAttribute('content')
        default_locale = el.getAttribute('content')
        break

    if !default_locale? or default_locale == ''
      default_locale = conf_lang ? 'fr_FR'

    # This regexp is put here as it should be call only once
    relax_bcp47_regexp = /^([a-zA-Z]{2})(?:[._-]([a-zA-Z]{2}))?$/
    match = relax_bcp47_regexp.exec(default_locale)
    return 'fr_FR' unless match?
    match = match.slice(1)
    match = @_iso639_to_bcp47(match) unless match[1]?
    match[1] = match[1].toUpperCase()
    match.join('_')

  # Return the human readable locale name for the current lang
  #
  # @private
  #
  # @return [String] the locale name
  _locale_name: ->
    lang_code = @main_scope.get 'lang'
    return 'Français' if !lang_code? or !AVAILABLE_LANGUAGES[lang_code]?
    AVAILABLE_LANGUAGES[lang_code]


module.exports = i18nHelper
