Parser = require('expr-eval').Parser
iraUtils = require('../utils.coffee')

# Display or enable condition computing
#
# @mixin
#
conditionsHelper =
  # @private
  #
  # @param condition [String] The condition to resolve
  # @return [Boolean]
  _compute_condition: (condition) ->
    condition = condition.trim()

    # We are not there to do math. And for historical reasons (*woops*),
    # we have to consider `!` as an alias for `not`
    if condition.indexOf('!') != -1
      condition = condition.replace(/!(?!=) ?/g, 'not ')

    if condition of @conditions.cache
      return @conditions.cache[condition]

    loc_context = {}
    pp = new Parser({ operators: { 'in': true, '!': false } })
    expr = pp.parse(condition)
    for v in expr.variables({ withMembers: true })
      new_v = iraApp.keyOrAlias(v)
      loc_val = @get(new_v, true)
      if !loc_val[0]? and !loc_val[1]
        # Update context to wait for the value of new_v
        @context = @_set_deep new_v, null, @context
      ctx_val = loc_val[0] ? ''
      loc_context = @_set_deep v, ctx_val, loc_context

      if @conditions.refs[new_v]?
        # Ensure we have the right value
        @conditions.refs[new_v]['value'] = loc_val[0]
        unless condition in @conditions.refs[new_v]['conds']
          @conditions.refs[new_v]['conds'].push(condition)
      else
        @conditions.refs[new_v] =
          value: loc_val[0]
          conds: [condition]

    try
      cond_result = expr.evaluate(loc_context)
      if typeof(cond_result) != 'boolean'
        cond_result = !!cond_result
    catch error
      console.warn "compute_condition crash for #{condition}, false is returned"
      console.warn loc_context
      console.warn error
      cond_result = false

    @conditions.cache[condition] = cond_result
    cond_result

  # @private
  #
  # @param clean_key [String] The condition to revoke
  # @param value [String] The old value of the variable involved (opt.)
  # @return [Boolean]
  _revoke_old_condition: (clean_key, value = null) ->
    return true unless clean_key of @conditions.refs
    # If a value is given, we check if the new value change something in
    # cached condition, responsible for hiding or showing some page
    # part.
    # That is to say, if value is given and NOT different from the
    # previous value we do nothing
    if value? and value == @conditions.refs[clean_key]['value']
      return true
    # Otherwise we actually revoke the condition
    for cached_cond in @conditions.refs[clean_key]['conds']
      delete @conditions.cache[cached_cond]
    delete @conditions.refs[clean_key]
    true

  # @private
  #
  # @param elem [HTMLElement] The element to hide or show
  # @param condition [String] The condition to compute
  _actually_hide_or_show_element: (elem, condition) ->
    return false unless condition?
    if @_compute_condition(condition)
      iraUtils.showElement(elem)
      return true
    iraUtils.hideElement(elem)
    false

  # @private
  #
  # @param elem [HTMLElement] The element to hide or show
  _handle_disabled_condition: (elem) ->
    condition = elem.getAttribute('data-ira-disabled')
    return unless condition?
    elem['iraForceDisable'] = @_compute_condition(condition)

  # @private
  #
  # @param elem [HTMLElement] The element to hide or show
  _handle_hidden_condition: (elem) ->
    condition = elem.getAttribute('data-ira-hidden')
    @_actually_hide_or_show_element(elem, condition)

  # @private
  #
  # @param elem [HTMLElement] The element, whose visibility must be toggled
  _handle_visible_condition: (elem) ->
    condition = elem.getAttribute('data-ira-visible')
    return unless condition?
    must_disable = !@_actually_hide_or_show_element(elem, condition)
    # By default, isFormElement return false if the current element is
    # disabled
    if iraUtils.isFormElement(elem, false)
      elem['iraForceDisable'] = must_disable
    else
      # disable all elem children (if data-ira-visible is holded by a
      # DIV or other HTML element)
      for el in elem.querySelectorAll('input, select, textarea')
        continue if el.type? and el.type in ['submit', 'button']
        el['iraForceDisable'] = must_disable

  # @private
  #
  # Set or unset the `required` attribute of an HTML Element, following
  # the current state of its `data-ira-required` attribute.
  #
  # @param elem [HTMLElement] The element to manage
  _handle_required: (elem) ->
    condition = elem.getAttribute('data-ira-required')
    return unless condition?
    if @_compute_condition(condition)
      elem.setAttribute('required', true)
    else
      elem.removeAttribute('required')


module.exports = conditionsHelper
