iraUtils = require('../utils.coffee')

# Prepare the DOM for iraApp usage
#
# @mixin
#
domHelper =
  _fire_event: (event_name) ->
    try
      evt = new Event(event_name)
    catch
      evt = document.createEvent 'Event'
      evt.initEvent event_name, true, true
    document.dispatchEvent(evt)

  _check_and_fire_readiness: ->
    return unless @loadingComplete()
    return if @_first_rendering_complete
    @_first_rendering_complete = true
    @_fire_event 'iraAppReady'

  runWhenReady: (callback) ->
    if @loadingComplete() or @_first_rendering_complete
      callback.call(@)
      return
    document.addEventListener 'iraAppReady', callback

  _parse_new_fields: (scope_uuid) ->
    scope = @getScope(scope_uuid)
    while scope.type != 'form'
      return unless scope.parentUuid?
      if scope.uuid == scope.parentUuid
        # Weird case, which only occurs with buggy markup.
        console.warn 'Parent and child scope have the same UUID', scope.uuid
        return
      scope = @getScope(scope.parentUuid)
    return unless scope?
    scope._parse_new_fields()

  isInThatScope: (elem, uuid) ->
    # Special check for the first level.
    if elem['iraScopeUuid']?
      return true if elem['iraScopeUuid'] == uuid
      # The weird scope type test is there to circumvent forms, which
      # may contains loops over options in select or checkboxes
      return false unless iraUtils.isFormElement(elem)
      if elem['form']?['iraScopeUuid']? and elem.form.iraScopeUuid == uuid
        return true
      return false
    elem = elem.parentElement
    while elem?
      if elem['iraScopeUuid']?
        # Cool, that's me
        return true if elem['iraScopeUuid'] == uuid
        # Humpf, not me. Pass please
        return false
      else if elem.hasAttribute('data-ira-for') or elem.tagName == 'FORM'
        # Woopsie, my parent is a future scope, pass please
        return false
      elem = elem.parentElement
    true


module.exports = domHelper
