iraUtils = require('./utils.coffee')


extract_best_case = (orig_pic, all_keys, possible_size) ->
  if all_keys[1] of orig_pic
    # Gotcha, we have a valid attribute at index 1
    if all_keys[0] in possible_size
      # Yeay, the size is ok
      return orig_pic[all_keys[1]] + '_' + all_keys[0]
    # else return original size
    return orig_pic[all_keys[1]]
  # Weird, what the fuck was this attribute ?
  return orig_pic['original'] if 'original' of orig_pic
  undefined

extract_pic = (key, cur_key) ->
  scope = iraApp.getScope(@['scope_level'])
  return undefined unless scope?

  all_keys = key.split('.').reverse()

  if all_keys[0] == cur_key
    # There is no arguments, return default size
    orig_pic = scope.get("#{key}_i10t")
    return undefined unless orig_pic?
    return orig_pic if typeof(orig_pic) == 'string'
    return orig_pic['original'] if 'original' of orig_pic
    return undefined

  # Where is cur_key
  cki = all_keys.indexOf(cur_key)
  # Replace it with _i10t suffix
  all_keys[cki] = all_keys[cki] + '_i10t'
  new_key_string = all_keys.slice(cki).reverse().join('.')
  orig_pic = scope.get(new_key_string)

  return undefined unless orig_pic?

  # If there is no _meta key present, we must be in presence of
  # an old converted picture. Thus no size is available
  possible_size = ['small', 'medium']
  possible_size = [] unless '_metas' of orig_pic

  # Better case, cki == 2, thus size should be at index 0 and
  # attribute at index 1
  if cki == 2
    return extract_best_case orig_pic, all_keys, possible_size

  # Maybe we just don't have a size
  if cki == 1
    # return original size
    return orig_pic[all_keys[0]] if all_keys[0] of orig_pic

  # Weird, what the fuck do we have ask for Oo?
  return orig_pic['original'] if 'original' of orig_pic
  undefined


class iraModel
  constructor: (xhr_data, @api_property = null) ->
    xhr_data ?= {}
    if xhr_data['api_model']? and !@api_property?
      @api_property = xhr_data.api_model
      delete xhr_data['api_model']

    if @api_property of xhr_data
      @data = xhr_data[@api_property]
    else
      @data = xhr_data

    @model_conf = iraApp.config?.symbols?.models?[@api_property] ? {}
    @_enrich_data()

  _enrich_data: ->
    i18n = iraApp.main_scope.get('lang')
    for key, value of @data
      if typeof(value) == 'undefined' or value == 'undefined'
        delete @data[key]
        continue
      if value == 'null'
        @data[key] = null
        value = null
      continue unless value?
      if key in ['reference', 'parent', 'origin']
        bound_object = new iraModel(value, key)
        @data[key] = bound_object.data
        continue
      suffix = key.slice(-5)
      field_data = @model_conf[key] ? { type: 'char' }
      if suffix == '_i18n'
        key_clean = key.slice(0, -5)
        @data[key_clean] = @_i18n_value(key_clean, value, i18n)
      else if suffix == '_i10t'
        key_clean = key.slice(0, -5)
        if typeof(value) == 'string'
          @data[key_clean] = value
          continue
        @data[key_clean] = extract_pic
        sub_keys = ['original', 'original.medium', 'original.small']
        if @data[key]['_metas']?
          for sk in Object.keys(@data[key]['_metas'])
            sub_keys.push sk
            sub_keys.push "#{sk}.medium"
            sub_keys.push "#{sk}.small"
        @data["_#{key_clean}_subkeys"] = sub_keys
      if field_data['type'] == 'monetary'
        formatted_key = "#{key}_formatted"
        @data[formatted_key] = parseFloat(value).formatMoney(@data['currency'])
    @_extract_contact_fields()
    @_add_is_mine_method()
    @_add_share_urls() if @data['id']?
    @data

  _extract_contact_fields: ->
    # Contacts related fields
    if @data['default_email']? and !@data['email']?
      @data['email'] = @data['default_email']

    # Address fields are expected to be at the root in any client request
    if @data['address']?
      @data['street1'] = @data.address['street1']
      @data['street2'] = @data.address['street2']
      @data['street3'] = @data.address['street3']
      @data['street4'] = @data.address['street4']
      @data['zip'] = @data.address['zip']
      @data['city'] = @data.address['city']
      @data['country_code'] = @data.address['country_code']
      @data['country_i18n'] = @data.address['country_i18n']
      delete @data['address']

  _add_is_mine_method: ->
    @data['is_mine'] = (key) ->
      obj = @
      for p in key.split('.')
        break if p == 'is_mine'
        obj = obj[p]
      return false unless obj?['owner']?['id']?
      return false unless iraApp.main_scope.get('isLoggedIn')
      obj.owner.id == iraApp.main_scope.get('user.id')

  _add_share_urls: ->
    @data['share_url'] = iraUtils.shareUrl(@api_property, @data['id'])
    @data['entity_url'] = iraUtils.entityUrl(@api_property, @data['id'])

  _i18n_value: (attr, value, default_lang) ->
    return value[default_lang] if value[default_lang]?
    up_langs = Object.keys(value)
    return value[up_langs[0]] if up_langs.length == 1
    root_lang = default_lang.split('_')[0]
    return value[root_lang] if value[root_lang]?
    rllen = root_lang.length
    for l in up_langs
      return value[l] if l.slice(0, rllen) == root_lang
    value[up_langs[0]]


module.exports = iraModel
