iraUtils = require('../utils.coffee')

page_tag_regxep = /<var>\s*{{\s*page\s*}}\s*<\/var>/g

# Handle pagination bars and query params
#
# @mixin
#
paginateHelper =
  # @private
  _init_paginate: ->
    # In all case (even without pagination at all), we need to extract
    # the query parameters
    @config['paginate_vars'] = []
    for p in Object.values(@getPaginateData(true))
      pv = @config['paginate_vars'].concat(@_extract_tags_from(p))
      @config['paginate_vars'] = pv.uniq()

    @paginate_bars = []
    return unless @root.parentElement?.id?

    loc_paginates = document.querySelectorAll(
      "[data-ira-paginate='#{@root.parentElement.id}']")
    return if loc_paginates.length == 0
    # Transform HTMLElement collection to raw Array
    @paginate_bars = (lp for lp in loc_paginates)

    # Init paginate data
    @_set_paginate_page(-1)
    @_init_paginate_templates()

  # @private
  _init_paginate_templates: ->
    @paginate_templates = []
    for pb in @paginate_bars
      lpt = {}
      prevpage = pb.querySelector('[data-ira-action=previous]')
      lpt['previous'] = prevpage.cloneNode(true) if prevpage?
      nextpage = pb.querySelector('[data-ira-action=next]')
      lpt['next'] = nextpage.cloneNode(true) if nextpage?
      gotopage = pb.querySelector('[data-ira-action=gotopage]')
      lpt['goto'] = gotopage.cloneNode(true) if gotopage?
      curpage = pb.querySelector('[value=page]')
      lpt['curpage'] = curpage.cloneNode(true) if curpage?
      ellipsis = pb.querySelector('[value=ellipsis]')
      if ellipsis?
        lpt['ellipsis'] = ellipsis.cloneNode(true)
        lmb = 7
        if ellipsis.hasAttribute('data-ira-paginate-max-page')
          lmb = parseInt(ellipsis.getAttribute('data-ira-paginate-max-page'))
          lmb = 7 if isNaN(lmb) or lmb < 7
          lmb += 1 if ((lmb - 1) % 2) != 0
        lpt['paginate_max_buttons'] = lmb
      pb.innerHTML = ''
      @paginate_templates.push lpt
    @paginate_templates

  # @private
  #
  # @return [Integer] The current page number
  _get_paginate_page: ->
    return 1 unless @root.hasAttribute('data-ira-paginate-page')
    cp = parseInt @root.getAttribute('data-ira-paginate-page')
    return 1 if isNaN(cp)
    cp

  # @private
  #
  # The `total_page` parameters defaults to -1, which may be interpreted
  # as an irrelevant information.
  #
  # If the `current_page` number equals -1, this method will try to
  # retrieve it by calling
  # {paginateHelper~_get_paginate_page _get_paginate_page}.
  #
  # @param current_page [Integer] The current page number
  # @param total_page [Integer] The total number of page
  _set_paginate_page: (current_page, total_page = -1) ->
    # Update current page info
    current_page = @_get_paginate_page() if current_page == -1
    @root.setAttribute('data-ira-paginate-page', current_page)
    @root.setAttribute('data-ira-paginate-page-count', total_page)

  # @private
  #
  # The `ptemplate` element will be cloned during processing.
  #
  # @param current_page [Integer] The current page number
  # @param ptemplate [HTMLElement] The DOM node to use as a template
  # @return [HTMLElement]
  _build_paginate_page_button: (current_page, ptemplate) ->
    if current_page in ['previous', 'next']
      action = current_page
      loc_p = ptemplate[action].cloneNode(true)
    else
      action = 'gotopage'
      loc_p = ptemplate['goto'].cloneNode(true)
      loc_p.innerHTML = loc_p.innerHTML.resetAndReplace(
        page_tag_regxep, current_page)
    a_p = loc_p.querySelector('a')
    if a_p?
      a_p.setAttribute('data-ira-action', action)
      loc_p.removeAttribute('data-ira-action')
      iraUtils.initAction a_p, @uuid
      return loc_p
    iraUtils.initAction loc_p, @uuid
    loc_p

  # Create the pagination trail
  #
  # By default and for the following examples
  # - z = total_page
  # - 7 = max_page
  #
  # ## For the first pages
  #
  # ```
  # [1][2][3][4][5][…][z]
  #              |_ frontier
  # ```
  #
  # ## For the last pages
  #
  # ```
  # [z-6][z-5][z-4][z-3][z-2][z-1][z]
  #    |_ total_page - (max_page + 1)
  # [z-6][z-5][z-4][z-3][z-2][z-1][z]
  #             |_ total_page - (max_page + 3)
  # [1]  […]  [z-4][z-3][z-2][z-1][z]
  #             |_ frontier
  # ```
  #
  # ## For the middle pages
  #
  # n = current_page
  #
  # ```
  # [1][…][n-1][n][n+1][…][z]
  # ```
  #
  # @private
  _build_paginate_page_trail: (current_page, max_page, total_page) ->
    return [1..total_page] if total_page <= max_page
    frontier = max_page - 2
    if current_page < frontier
      return [1..frontier].concat ['…', total_page]
    frontier = total_page - max_page + 3
    if current_page > frontier
      return [1, '…'].concat [frontier..total_page]
    around = Math.floor((max_page - 5) / 2)
    trail = [1, '…']
    for p in [(current_page - around)...current_page]
      trail.push p
    for p in [current_page..(current_page + around)]
      trail.push p
    trail.concat ['…', total_page]

  # @private
  _update_one_paginate_bar: (pbar, ptemplate, current_page, total_page) ->
    # Build buttons
    pbar.innerHTML = ''

    total_page = parseInt(total_page)
    if isNaN(total_page)
      total_page = -1

    prev_next_class = pbar.getAttribute('data-ira-paginate-prev-next-class')
    hide_prev = (prev_next_class == 'hidden' and current_page == 1)

    if ptemplate['previous']? and total_page > 1 and !hide_prev
      loc_p = @_build_paginate_page_button('previous', ptemplate)
      if prev_next_class? and current_page == 1
        iraUtils.addClass(loc_p, prev_next_class)
      pbar.appendChild(loc_p)

    if total_page > 0
      current_page = parseInt(current_page)
      if ptemplate['ellipsis']?
        page_trail = @_build_paginate_page_trail \
          current_page, ptemplate['paginate_max_buttons'], total_page
      else
        page_trail = [1..total_page]

      for curpage in page_trail
        if curpage == current_page
          continue unless ptemplate['curpage']?
          loc_p = ptemplate.curpage.cloneNode(true)
          loc_p.innerHTML = loc_p.innerHTML.resetAndReplace(
            page_tag_regxep, curpage)
        else if curpage == '…'
          # curpage == '…' are only generated when we do have the
          # ellipsis template
          loc_p = ptemplate.ellipsis.cloneNode(true)
          loc_p.innerHTML = loc_p.innerHTML.replace(page_tag_regxep, curpage)
        else
          continue unless ptemplate['goto']?
          loc_p = @_build_paginate_page_button(curpage, ptemplate)
        pbar.appendChild(loc_p)

    hide_next = (prev_next_class == 'hidden' and current_page == total_page)
    if ptemplate['next']? and total_page > 1 and !hide_next
      loc_p = @_build_paginate_page_button('next', ptemplate)
      if prev_next_class? and current_page == total_page
        iraUtils.addClass(loc_p, prev_next_class)
      pbar.appendChild(loc_p)

  updatePaginateBars: (current_page, total_page) ->
    return true if @paginate_bars.length == 0
    @_set_paginate_page(current_page, total_page)

    for pbar, index in @paginate_bars
      ptemplate = @paginate_templates[index] ? @paginate_templates[0]
      @_update_one_paginate_bar(pbar, ptemplate, current_page, total_page)

  # Extract search query and pagination configuration from the
  # data-ira-query attribute.
  #
  # By default, this method try to resolve tags in the data-ira-query
  # attribute. Sometimes, we just want to access the data without losing
  # time replacing tags in it. This is the goal of the `keep_tags`
  # attribute.
  #
  # @param keep_tags [Boolean] if true, do not try to replace tags
  # @return [Object] the search and pagination config
  getPaginateData: (keep_tags = false) ->
    default_data =
      type: 'search'
      page: @_get_paginate_page()
    if !@root.hasAttribute('data-ira-query')
      return default_data
    raw_data = @root.getAttribute('data-ira-query')
    return default_data if raw_data == ''
    return { type: 'list' } if raw_data in ['list', 'all', '*']
    unless keep_tags
      raw_data = @_replace_tags_in(raw_data)
      # If the query changes, it may be because of a search query
      # parameter change. Thus we have to force the page number back to
      # 1.
      if @root['iraOldQuery']? and @root['iraOldQuery'] != raw_data
        default_data['page'] = 1
      @root['iraOldQuery'] = raw_data
    default_data[k] = v for k, v of iraUtils.getQueryData(raw_data)
    default_data

  setPaginateData: (data, keep_tags = false) ->
    query = []
    for key, value of data
      if key == 'page'
        @root.setAttribute('data-ira-paginate-page', value)
      else if Array.isArray(value)
        for v in value
          v = encodeURIComponent(v) unless keep_tags
          query.push "#{key}[]=#{v}"
      else
        value = encodeURIComponent(value) unless keep_tags
        query.push "#{key}=#{value}"
    @root.setAttribute 'data-ira-query', query.join('&')


module.exports = paginateHelper
