iraUtils = require('./utils.coffee')
iraAction = require('./action.coffee')
iraModel = require('./model.coffee')

mustache_tag_regexp = /\{\{([^}]+)\}\}/g

# Main context holder
#
# @include conditionsHelper
# @include contextHelper
# @include formHelper
#
class iraScope
  constructor: (@root = null, @parentUuid = null, @context = {}) ->
    # scope may have a custom @uuid
    @uuid = (@root.id ? @root.name) if @root?
    @uuid = iraUtils.uniqueId() if !@uuid? or @uuid == ''
    # Add self to the dom as soon as possible in order to be accessible
    # during fetchData or other.
    # We use the window property and not a global accessor to work in
    # test environment with a strip down DOM behavior.
    window['iraApp'].scopes_store[@uuid] = @
    @context['scope_level'] = @uuid
    @subscopes = {}
    @symbols =
      elements: {}
      attributes: {}
    @conditions =
      cache: {}
      refs: {}

    # May be 'simple', 'main_scope', 'named' or 'form'
    # - root: when it is the main scope of a webpage, the one created
    #         by iraApp directly while parsing the page;
    # - named: any scope created because of a `data-ira-for` attribute
    # - form: any scope created because of a `form` tag
    @type = 'simple'
    @config = {}

    @bootstrap() if @root? and @parentUuid?

  bootstrap: ->
    # Stop execution here in test env
    return if iraApp.testEnv()

    @root['iraScopeUuid'] = @uuid

    if @root.hasAttribute('data-ira-for')
      @type = 'named'
      @_extract_scope_fences()
      @_init_paginate()
      @root.style.display = 'none'
    else if @root.tagName == 'FORM'
      @type = 'form'
      @_parse_form()
    # Now extract all subscopes
    @_extract_subscopes()
    # Then extract my own variables
    @_extract_all_variables()
    # Process visibility and required fields once
    @_handle_visibility_and_required()
    # Init actions
    for el in @root.querySelectorAll('[data-ira-action]')
      iraUtils.initAction(el, @uuid)
    # fetchData must be done AFTER subscopes have been emptied to avoid
    # scope collisions
    @fetchData()

  # @private
  _extract_subscopes: (force = false) ->
    for elem in @root.querySelectorAll('[data-ira-for], form')
      if elem.hasAttribute('data-ira-for')
        if elem.getAttribute('data-ira-for') == 'else'
          # The else blocks will be used later
          iraUtils.hideElement(elem)
          continue
        else if @_is_in_another_scope(elem)
          continue
      new_scope = new iraScope(elem, @uuid)
      @subscopes[new_scope.uuid] = new_scope

  # @private
  _extract_scope_fences: ->
    scope_model = @root.getAttribute('data-ira-for')

    # Begin by cleaning the @rootClone to avoid infinite loop
    @rootClone = @root.cloneNode(true)
    @rootClone.removeAttribute('data-ira-for')
    @rootClone.removeAttribute('data-ira-as')
    @rootClone.removeAttribute('data-ira-paginate-page')
    @rootClone.removeAttribute('data-ira-paginate-page-count')
    @rootClone.removeAttribute('data-ira-query')

    @root.removeAttribute('data-ira-for')
    scope_tag = scope_model
    dot_idx = scope_model.lastIndexOf '.'
    scope_dep = null
    if dot_idx != -1
      # We take the second element because we are in a loop
      # declaration and the developper probably wants to declare a
      # loop over a subproperty of an object with a variable of the
      # form event.collect_pages We want collect_page, not event.
      scope_model = scope_tag.slice dot_idx + 1
      # scope_dep can now contain dots
      scope_dep = scope_tag.slice 0, dot_idx

    # Must be a plural
    scope_tag = scope_tag.pluralize()

    # Remove plural mark if it's present
    scope_model = scope_model.singularize()

    if scope_dep == 'my'
      # We are in presence of a mytrucmuche loop. Force the type of the
      # loop. By default, getPaginateData try to resolve tags in the
      # data-ira-query attribute. This is not the time to do this, thus
      # just pass the `keep_tags` parameter as true.
      scope_dep = null
      lq = @getPaginateData(true)
      lq['type'] = 'my'
      @setPaginateData(lq, true)

    @config =
      model: scope_model
      model_as: scope_model
      tag_ref: scope_tag
      dependency: scope_dep
      already_fetched: false
      drop_children: true

    if @root.hasAttribute('data-ira-as')
      @config['model_as'] = @root.getAttribute('data-ira-as')
      @root.removeAttribute('data-ira-as')

  # Return any tags present in the given `text`
  #
  # The found tags are expanded through {iraApp#keyOrAlias}.
  #
  # @param text [String]
  # @return [Array<String>] all the found tags
  _extract_tags_from: (text) ->
    tag_data = mustache_tag_regexp.resetAndExec(text)
    return [] if !tag_data?
    found_tags = []
    while tag_data?
      found_tags.push iraApp.keyOrAlias(tag_data[1])
      # Prepare next loop iteration
      tag_data = mustache_tag_regexp.exec(text)
    found_tags

  # Extract all potential tags from the `content` text, which is
  # the value of the `attr` attribute of the given `elem` element.
  #
  # @private
  # @param content [String] The text from which tags may be extracted
  # @param elem [HTMLElement] From which element the attribute is observed
  # @param attr [String] The attribute name
  # @param attr_cap [String] The attribute name camel cased
  _extract_attr: (content, elem, attr) ->
    attr_cap = attr.replace('-', ' ').titleize().replace(' ', '')
    tag_data = mustache_tag_regexp.resetAndExec(content)
    return if !tag_data? or @_is_in_another_scope(elem)
    elem["ira#{attr_cap}OldValue"] = content
    elem["ira#{attr_cap}Tags"] = {}
    while tag_data?
      cur_tag = tag_data[1]
      # Prepare next loop iteration
      tag_data = mustache_tag_regexp.exec(content)
      # Extract clean tag from a potential tag + filter constuction
      _td = @_remove_filters(cur_tag)
      _fal = @_filter_params(cur_tag, 'else')
      clean_tags = [iraApp.keyOrAlias(_td)]
      clean_tags.push(_fal[0]) if _fal?
      for _ct in clean_tags
        elem["ira#{attr_cap}Tags"][_ct] =
          attr: cur_tag
          value: undefined
        @symbols.attributes[_ct] ?= {}
        @symbols.attributes[_ct][attr] ?= []
        continue if elem in @symbols.attributes[_ct][attr]
        @symbols.attributes[_ct][attr].push(elem)

  # Extract all data-ira-attr attributes for the given HTML element
  #
  # @private
  # @param elem [HTMLElement] the element to parse
  # @return [HTMLElement] the same element after extraction
  _extract_ira_attr: (elem) ->
    rattr = ['data-ira-var']
    for dattr in elem.attributes
      dattr = dattr.name
      continue unless dattr[0..13] == 'data-ira-attr-'
      attr = dattr.slice(14)
      @_extract_attr(elem.getAttribute(dattr), elem, attr)
      rattr.push dattr
    elem.removeAttribute(a) for a in rattr
    elem

  # Replace any tags present in the given `text` by the value
  # currently in the context.
  #
  # @param text [String]
  # @return [String] `text` will all tags replaced
  _replace_tags_in: (text) ->
    nt = text.replace mustache_tag_regexp, (m, p1) =>
      @get(p1) ? ''
    nt

  # Replace the given `clean_tag` by its corresponding value for the
  # `attr` attribute of the given `elements` HTML elements.
  #
  # @private
  # @param clean_tag [String] The tag to replace by its current value
  # @param attr [String] The attribute for which we replace tags
  # @param elements [Array<HTMLElement>] The elements concerned by `attr`
  _replace_attrs: (clean_tag, attr, elements) ->
    attr_cap = attr.replace('-', ' ').titleize().replace(' ', '')
    for elem in elements
      continue if !elem["ira#{attr_cap}OldValue"]? or
                  !elem["ira#{attr_cap}Tags"]?
      attr_value = elem["ira#{attr_cap}OldValue"]
      continue unless clean_tag of elem["ira#{attr_cap}Tags"]
      # Ok we must be able to change this tag display. However there may
      # be several tag  to replace in this attribute. So we must loop
      # here…
      for ct, ctval of elem["ira#{attr_cap}Tags"]
        if ct == clean_tag
          tag_value = @get(ctval['attr'])
          tag_value = '' unless tag_value?  # Avoid to print null/undefined
          continue if tag_value == ctval['value']
          elem["ira#{attr_cap}Tags"][ct]['value'] = tag_value
        else
          tag_value = ctval['value']
        _rattr = ctval['attr'].replace(/\|/g, '\\|')
        r = new RegExp("{{#{_rattr}}}", 'g')
        attr_value = attr_value.replace(r, tag_value)
        if attr in ['doc-title', 'form-option']
          elem.textContent = attr_value
          continue
        elem.setAttribute(attr, attr_value)
        if attr == 'value' and iraUtils.isFormElement(elem)
          @set(elem.name, attr_value)

  # Replace all the occurences of `clean_tag` by its current value.
  #
  # @private
  # @param clean_tag [String] The tag to replace.
  _replace_variables: (clean_tag) ->
    if @symbols.elements[clean_tag]?
      for elem in @symbols.elements[clean_tag]
        if elem['iraIgnoreVar']? and clean_tag in elem['iraIgnoreVar']
          continue
        orig_tag = elem['iraOriginalTag'] ? clean_tag
        tag_value_data = @get(orig_tag, true)
        if tag_value_data[2] == 'counter'
          @_fetch_counter_value(tag_value_data[3..], elem)
          tag_value = 0
        else
          tag_value = tag_value_data[0] ? '' # Avoid to print null/undefined
        if elem.getAttribute('data-ira-type') == 'html'
          elem.innerHTML = unescape(tag_value)
        else
          elem.textContent = tag_value
    return unless @symbols.attributes[clean_tag]?
    for attr, elements of @symbols.attributes[clean_tag]
      @_replace_attrs(clean_tag, attr, elements)

  # Fire the event, which name is passed as parameter
  #
  # This method is currently used to fire the iraRenderingEvent. This
  # event helps integrators to do something after the current scope has
  # finish to draw its content.
  #
  # Passing the origin method name as second parameter allows
  # integrators to filter between early rendering state (when called
  # from the {iraScope#render render} method) and final rendering state
  # after a successfull ajax call (when fired from the
  # {iraScope#update update} method).
  #
  # @param event_name [String] The event name.
  # @param caller [String] the method, which fires the event
  fireEvent: (event_name, caller) ->
    try
      loc_event = new Event(event_name)
    catch
      loc_event = document.createEvent 'Event'
      loc_event.initEvent event_name, true, true
    loc_event['iraScopeUuid'] = @uuid
    loc_event['iraEventSource'] = caller
    document.dispatchEvent(loc_event)

  # Check if the given `elem` HTML element is inside the `@root` element
  # of the current scope or not.
  #
  # @private
  # @param elem [HTMLElement] The element to check
  # @return [Boolean]
  _is_in_another_scope: (elem) ->
    !iraApp.isInThatScope(elem, @uuid)

  # @private
  _extract_all_variables: ->
    # Is there any new var tags to inspect?
    for elem in @root.querySelectorAll('var')
      text_content = elem.textContent.trim()
      continue if text_content == ''
      continue if @_is_in_another_scope(elem)
      tag = text_content.resetAndReplace(mustache_tag_regexp, '$1')
      continue if tag == ''
      # Extract clean tag from a potential tag + filter constuction
      clean_tag = iraApp.keyOrAlias(@_remove_filters(tag))
      new_span = document.createElement('SPAN')
      new_span['iraScopeUuid'] = @uuid
      # Only add iraOriginalTag if we don't have a clean tag already
      new_span['iraOriginalTag'] = tag if clean_tag != tag
      for dattr in elem.attributes
        new_span.setAttribute(dattr.name, dattr.value)
        continue unless dattr.name == 'data-ira-query'
        query_raw = dattr.value
        other_vars = []
        for v in Object.values(iraUtils.getQueryData(query_raw))
          ov = other_vars.concat(@_extract_tags_from(v))
          other_vars = ov.uniq()
        for v in other_vars
          new_span['iraIgnoreVar'] ?= []
          new_span['iraIgnoreVar'].push(v)
          @symbols.elements[v] ?= []
          @symbols.elements[v].push(new_span)
      iraUtils.addClass(new_span, 'ira-flow-var')
      c_tags = [clean_tag]
      _fal = @_filter_params(tag, 'else')
      c_tags.push(_fal[0]) if _fal?
      for _ct in c_tags
        @symbols.elements[_ct] ?= []
        @symbols.elements[_ct].push(new_span)
      elem.parentElement.replaceChild(new_span, elem)

    # Options are very specific html tags
    for elem in @root.querySelectorAll('option')
      continue if @_is_in_another_scope(elem)
      @_extract_attr(elem.textContent, elem, 'form-option', 'FormOption')
    if @root.tagName == 'OPTION'
      @_extract_attr(@root.textContent, @root, 'form-option', 'FormOption')

    # Then look for new attributes to observe
    for elem in @root.querySelectorAll('[data-ira-var]')
      continue if @_is_in_another_scope(elem)
      @_extract_ira_attr(elem)
    # Does root has data-ira-attr attributes?
    @_extract_ira_attr(@root) if @root.hasAttribute('data-ira-var')

  # @private
  _replace_all_variables: ->
    @_extract_all_variables()
    tag_to_parse = Object.keys(@symbols.elements) \
      .concat(Object.keys(@symbols.attributes)).uniq()
    for clean_tag in tag_to_parse
      @_replace_variables(clean_tag)

  # @private
  _handle_visibility_and_required: ->
    for elem in @root.querySelectorAll('[data-ira-visible]')
      continue if @_is_in_another_scope(elem)
      @_handle_visible_condition(elem)
    @_handle_visible_condition(@root)
    for elem in @root.querySelectorAll('[data-ira-hidden]')
      continue if @_is_in_another_scope(elem)
      @_handle_hidden_condition(elem)
    @_handle_hidden_condition(@root)
    for elem in @root.querySelectorAll('[data-ira-disabled]')
      continue if @_is_in_another_scope(elem)
      @_handle_disabled_condition(elem)
    for elem in @root.querySelectorAll('[data-ira-required]')
      continue if @_is_in_another_scope(elem)
      @_handle_required(elem)
    return unless @root.tagName == 'INPUT'
    @_handle_disabled_condition(@root)
    @_handle_required(@root)

  # @private
  _reset_copycats: ->
    return unless 'lastCopycats' of @config
    for scope in @config['lastCopycats']
      child = scope.root
      child.parentElement.removeChild(child)
      delete iraApp.scopes_store[scope.uuid]
      delete @subscopes[scope.uuid]
    delete @config['lastCopycats']

  # Create a new sibling for the `@root` element, by instanting a
  # new subscope and finally {iraScope#render rendering} it.
  #
  # @private
  _render_sibling: (item, index = null, collection = []) ->
    root_elem = @rootClone.cloneNode(true)
    next_elem = @root.nextSibling
    if @config['lastCopycats']? and @config['lastCopycats'].length > 0
      lcat = @config['lastCopycats'][@config['lastCopycats'].length - 1]
      if lcat.root? and lcat.root.nextSibling?
        next_elem = lcat.root.nextSibling
    try
      if next_elem?
        @root.parentElement.insertBefore(root_elem, next_elem)
      else
        @root.parentElement.appendChild(root_elem)
    catch error
      console.error "Error inserting new sibling for
        scope #{@uuid}\n", error

    ctx = {}
    ctx['loop_index'] = index if index?
    if collection.length > 0
      ctx = @_set_deep @config['tag_ref'], collection, ctx
    ctx = @_set_deep @config['model_as'], item, ctx

    new_scope = new iraScope(root_elem, @uuid, ctx)
    @subscopes[new_scope.uuid] = new_scope
    @config['lastCopycats'].push new_scope
    new_scope.render()

  # Display or hide else element for loops, if it exists
  #
  # @private
  # @param must_show [Boolean] show or hide
  _toggle_empty_list_element: (must_show) ->
    else_el = null
    el = @root.nextSibling
    while el
      if el.nodeType == 1 and el.getAttribute('data-ira-for') == 'else'
        break
      el = el.nextSibling
    return @root unless el?
    if must_show
      iraUtils.showElement(el)
    else
      iraUtils.hideElement(el)
    # return @root to be compatible with @render method
    @root

  # Render all items received from the API
  #
  # This is a private function, only called from
  # {iraScope#update update} method.
  #
  # @private
  # @param model_name [String] the model name of the collection's items
  # @param collection [Array<Object>] the items to display
  # @return [mixed]
  _render_collection: (model_name, collection) ->
    if @config['drop_children']
      @_reset_copycats()
      @config['lastCopycats'] = []
    else
      # Only allow explicit usage of children dropping. Thus desactivate
      # it now for next call of render_collection
      @config['drop_children'] = true
    if collection.length == 0
      @set(model_name, undefined, true)
      @render()
      return @_toggle_empty_list_element(true)
    else
      @config['already_fetched'] = true
    col = []
    for item in collection
      col.push new iraModel(item, model_name)
    @_render_sibling(item.data, i, col) for item, i in col
    @_toggle_empty_list_element(false)
    if typeof(@config['callback']) == 'function'
      @config['callback'].call(@)

  # Look for the main scope's dependency id in the current context or in
  # the parent scope context if it is not found.
  #
  # @param dependency [String] The model name of this scope dependency
  # @return [String] the dependency ID. May be null.
  browseForDependencyId: (dependency) ->
    dep = @get(dependency)
    return dep.id if dep? and iraUtils.isPresent(dep.id)
    return null unless @parentUuid
    parent = iraApp.getScope(@parentUuid)
    return null unless parent?
    parent.browseForDependencyId(dependency)

  _fetch_counter_value: (matches, elem) ->
    @symbols['counters'] ?= []
    @symbols.counters.push(matches[0]) unless matches[0] in @symbols.counters
    loc_id = null
    if matches[1]?
      # We allow all type of counter parent, as soon as they have an id
      loc_id = @get "#{matches[1]}.id"
      # Race conditions, wait for parent object
      return unless loc_id?
    countAct = new iraAction(
      elem, '#' + [matches[3], 'counter'].join('/'), @)
    countAct.setArgument('tail', [matches[4], matches[0]])
    if elem.hasAttribute('data-ira-query')
      raw_data = elem.getAttribute('data-ira-query')
      for k, v of iraUtils.getQueryData(@_replace_tags_in(raw_data))
        countAct.setArgument(k, v)
    if loc_id?
      if matches[2].startsWith('total_')
        countAct.setArgument('parent', loc_id)
      else
        countAct.setArgument('id', loc_id)
        countAct.setArgument('model', matches[1])
    countAct.run()

  # Fetch scope needed data
  #
  # @return [Boolean] true if it spawn an ajax request, false otherwise
  fetchData: ->
    return false if @type != 'named'

    unless iraApp.isAllowedObject(@config['model'])
      # The data we need is NOT fetchable from the server and must be
      # obtained as part of something else.
      entities = @get(@config['tag_ref'])
      if entities?
        data =
          type: @config['model']
          count: entities.length
          entities: {}
        data.entities[e.id] = e for e in entities
        @update data
        return true
      # Ok, thus we have nothing to fetch… maybe it is a virtual scope?
      paginate_data = @getPaginateData()
      return false unless iraUtils.isPresent(paginate_data?['uuid'])
      data =
        type: @config['model']
        count: 1
        entities:
          "#{paginate_data['uuid']}":
            id: paginate_data['uuid']
            type: @config['model']
      @update data
      return true

    list_uri = ['#', @config['model'], '/list']
    listAct = new iraAction(@root, list_uri.join(''), @)

    if @config['dependency']?
      loc_dep = @config['dependency']
      loop_dep_id = @browseForDependencyId(loc_dep)
      return false unless loop_dep_id?
      @context = @_set_deep([loc_dep, 'id'], loop_dep_id, @context)
      listAct.setArgument 'model', loc_dep
      listAct.setArgument 'id', loop_dep_id

    listAct.setArgument(k, v) for k, v of @getPaginateData()
    listAct.run()

  # Call {iraScope#fetchData `fetchData`} only if it is required
  # (if the corresponding data are not found in the context).
  #
  fetchDataIfNeeded: ->
    return if @type != 'named'
    return if @get(@config['model_as'])? or @config['already_fetched']
    @fetchData()

  # Load more data for a named scope
  loadMore: (opts = {})->
    return if @type != 'named'
    if 'callback' of opts
      @config['callback'] = opts['callback']
    if 'nextpage' of opts
      nextpage = opts['nextpage']
    else
      curpage = @_get_paginate_page()
      if 'direction' of opts and opts['direction'] == 'previous'
        nextpage = curpage - 1
        nextpage = 1 if nextpage <= 0
      else
        nextpage = curpage + 1
    if 'drop_children' of opts
      @config['drop_children'] = opts['drop_children']
    @root.setAttribute('data-ira-paginate-page', nextpage)
    @fetchData()

  # In the case of the named scopes, return true if keys contains either
  # the scope dependency name, or one of the tags present in the
  # `data-ira-query` attribute. It returns false in all other cases.
  #
  # @param keys [Array<String>] the keys to test
  # @return [Boolean]
  mustRenderAgainFor: (keys) ->
    return false if @type != 'named'
    return true if @config['tag_ref'] in keys
    if @config['paginate_vars']? and @config['paginate_vars'].length > 0
      for k in keys
        return true if k in @config['paginate_vars']
    return @config['dependency'] in keys

  # @private
  _notify_or_render_subscopes: (new_keys) ->
    for scope in Object.values(@subscopes)
      unless scope.mustRenderAgainFor(new_keys)
        scope.notify(new_keys)
        continue
      # Some important data are required there. Force loop recomputing
      # please, and reset scope data
      scope.remove(scope.config['model_as'], true)
      scope.fetchDataIfNeeded()

  # Notify the scope that some values have changed for one of its
  # ancestors. This change may lead to updating a local variable,
  # display its new value or completely redraw the scope. In all cases,
  # this implies the revoking of all conditions involving the given
  # variables.
  #
  # @param all_keys [Array<String>] the keys, which have changed
  # @param from_myself [Boolean] guard clause to avoid var erasing
  notify: (all_keys, from_myself = false) ->
    # Stop execution here in test env
    return if iraApp.testEnv()
    if @type == 'main_scope'
      @_replace_variables(clean_tag) for clean_tag in all_keys
      @_notify_or_render_subscopes(all_keys)
      return
    flat_keys = @_flatten_subkeys('', @context)
    for k in Object.keys(@symbols.attributes)
      flat_keys.push k unless k in flat_keys
    for k in Object.keys(@symbols.elements)
      flat_keys.push k unless k in flat_keys
    for k in Object.keys(iraApp.main_scope.context)
      flat_keys.push k unless k in flat_keys
    if 'counters' of @symbols
      for k in @symbols.counters
        flat_keys.push k
        if k.indexOf('.') == -1
          all_keys.push k
        else if k.split('.')[0] in all_keys
          all_keys.push k
    new_keys = []
    something_changed = false
    counter_notification = false
    for k in all_keys
      k_part = k.split('.')
      # If there is no . in k, then k == k_part[0] and no need to check
      # both of them. Finally, we need to only check with the LAST part
      # of the key.
      if @type == 'named' and k_part[0] == @config['model_as'] and
         @_key_is_counter(k_part.pop())
        # So, this is a counter notification.
        counter_notification = true
      new_keys.push k
      continue unless k in flat_keys
      something_changed = true
      if !from_myself and @parentUuid?
        # the current scope has an old value for `k`. We need to update
        # it with the value coming from its parent.
        parentScope = iraApp.getScope(@parentUuid)
        @set(k, parentScope.get(k), true)
      @_revoke_old_condition(k)
      @_replace_variables(k)
    if something_changed
      @_handle_visibility_and_required()
      @_update_form_elements() if @type == 'form'
    return if counter_notification
    @_notify_or_render_subscopes(new_keys)
    null

  # Repaint all the scope-related elements WITHOUT revoking any
  # conditions.
  #
  # @return [HTMLElement] the @root element of this scope
  render: ->
    # Stop execution here in test env
    return @root if iraApp.testEnv()

    # Begin by rendering children scopes
    for scope in Object.values(@subscopes)
      scope.fetchDataIfNeeded()
      scope.render()

    # Then, do the rendering stuff
    @_handle_visibility_and_required()
    @_update_form_elements() if @type == 'form'
    @_replace_all_variables()
    @fireEvent('iraRenderingComplete', 'render')
    @root

  # Set variable in the context's scope by passing the raw API data
  # object.
  #
  # @param data [Object] raw data sended by the API
  # @return [Boolean]
  update: (data) ->
    model_name = @config['model']
    collec_name = model_name.pluralize()
    if 'type' of data and data['type'] == model_name
      if Array.isArray(data['entities'])
        # API v2 return an array instead of an object when no values are
        # available :/
        @_render_collection(model_name, [])
      else
        @_render_collection(model_name, Object.values(data['entities']))
    else if data[model_name]?
      # Add current item value to it
      loc_model = new iraModel(data[model_name], model_name)
      @set(@config['model_as'], loc_model.data)
    else
      @remove(@config['tag_ref'], true) if @config['tag_ref']?
      @remove @config['model_as']
    if 'total_pages' of data
      @updatePaginateBars data['page'], data['total_pages']
    true

  # Directly instantiate a new {iraActionRunner} and call it. This
  # method is usefull to call action from the scope's inside.
  #
  # Both `opts` attributes are optional, but it's kind of useless to
  # call {iraActionRunner} with both element null.
  #
  # The return value is the new {iraActionRunner} instance, after having
  # called {iraActionRunner#run `run`} method.
  #
  # @param opts [Object]
  # @option opts elem [HTMLElement] the action origin
  # @option opts action [String] the action string to execute
  # @return [iraActionRunner] the new {iraActionRunner} instance
  exec: (opts = {}) ->
    new iraAction(opts['elem'], opts['action'], @).run()


iraUtils.include iraScope, require('./scope/conditions.coffee')
iraUtils.include iraScope, require('./scope/context.coffee')
iraUtils.include iraScope, require('./scope/file_input.coffee')
iraUtils.include iraScope, require('./scope/form.coffee')
iraUtils.include iraScope, require('./scope/paginate.coffee')
module.exports = iraScope
