iraUtils = require('../utils.coffee')
iraModel = require('../model.coffee')


class iraMetaController
  constructor: (name) ->
    @api_end_point = name

  # Generate front API url, given a method and an optional suffix.
  #
  # @private
  #
  # @param method [String] the API endpoint to reach out
  # @param suffix [String] an optional deeper path fragment
  # @return [String] the complete URL to call
  _api_url: (method, suffix = null) ->
    url = [iraApp.config['api_url']]
    if method in ['view', 'search', 'list']
      method = 'search' if method == 'list'
      url = url.concat [method, @api_end_point]
    else
      url.push method
    url.push suffix if suffix?
    url.join('/')

  # @private
  _auth_header: ->
    authtoken = iraApp.main_scope.get('user-token')
    return {} unless iraUtils.isPresent(authtoken)
    { 'X-Access-Token': "Bearer #{authtoken}" }

  # Build ajax request with some default options.
  #
  # @private
  #
  # @param opts [Object] Fetch API init object
  # @see https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch fetch API on mozdev
  _request: (opts) ->
    return false unless opts['url']?
    target_url = opts['url']
    request = { method: 'GET' }
    for key, value of opts
      if key in ['success', 'error', 'context', 'url']
        continue
      request[key] = value

    # Ugly stuff to patch URL in case of GET call
    if request.method in ['GET', 'HEAD'] and request['body']?
      if target_url.indexOf('?') == -1
        target_url += '?'
      params = []
      for key, value of request['body']
        if Array.isArray(value)
          for v in value
            v = encodeURIComponent(v)
            params.push "#{key}[]=#{v}"
        else
          v = encodeURIComponent(value)
          params.push "#{key}=#{v}"
      target_url += params.join('&')
      delete request['body']

    request['headers'] ?= {}
    request['headers']['Content-Type'] = 'application/json'
    if opts['context']?
      callback_context = opts['context']
    else
      callback_context =
        scope: null
    callback_context['api_end_point'] = @api_end_point

    sig = target_url
    if callback_context['scope']?
      sig += '-' + callback_context['scope']?.uuid
    sig += '-' + JSON.stringify request['body'] if request['body']?

    callback_context['signature'] = sig
    callback_success = opts['success']

    return false if sig in iraApp.xhr_sig_store
    iraApp.xhr_sig_store.push sig

    fetch(target_url, request).then (response) ->
      if response.status == 500
        e = new Error('IRA_API_ERROR')
        e['message'] = 'API error 5xx'
        throw e
      response.json()
    .then (data) ->
      if request['method'] == 'GET' or data.valid or
         data.success or data.redirect
        hook_data = data
        hook_data['api_end_point'] = callback_context.api_end_point
        hook_data['method'] = callback_context.method
        iraApp.runHooks('onSuccess', callback_context.scope, hook_data)
        return true unless callback_success?
        return callback_success.call(callback_context, data)
      e = new Error('IRA_API_ERROR')
      e['message'] = 'API error invalid data'
      e['iraAPIData'] = data
      throw e
    .catch (error) ->
      error_msg = error['message'] ? error
      console.error error_msg
      scope = callback_context['scope']
      return false if !scope? or scope.type != 'form'
      xhrData =
        success: false
        error: 'INTERNAL_ERROR'
        message: error_msg
      if error['iraAPIData']?
        xhrData[k] = v for k, v of error['iraAPIData']
      c = new iraMetaController(callback_context['api_end_point'])
      c._display_errors(xhrData, scope.root)
    .then ->
      # Remove signature only if all is ok
      return true unless 'signature' of callback_context
      iraApp.xhr_sig_store.remove(callback_context['signature'])
      delete callback_context['signature']
      return true unless 'scope' of callback_context
      return true unless callback_context['scope']?
      if 'counter_tag' of callback_context
        # This is a counter call
        callback_context['scope'].fireEvent('iraRenderingComplete', 'counter')
      else
        callback_context['scope'].fireEvent('iraRenderingComplete', 'update')
      true

  # @private
  _handle_response: (xhrData, scope) ->
    # Always update the context
    if xhrData['context']? and scope?
      if scope.type == 'named'
        scope.update(xhrData.context)
      else if scope.type == 'form' and
              !Array.isArray(xhrData['context'])
        for model_name, item_list of xhrData['context']
          # Never store contact info
          # Customer should use onSuccess hook if they really want to.
          continue if model_name == 'contact'
          continue if typeof(item_list) == 'string'
          has_changed_someone = false
          children = Object.values(item_list)
          for item in children
            r =
              on: 'entity'
              value: item['id']
              entity_name: model_name
            scopes_list = iraApp.filterScopes(r)
            continue if scopes_list.length == 0
            entity = new iraModel(item, model_name)
            s.set(model_name, entity.data) for s in scopes_list
            has_changed_someone = true
          continue if has_changed_someone
          # We don't find any scope candidate for this entity. We will
          # look now if a single entity of this name exists in the
          # current context. In that case, we postulate this was an
          # object creation and the API just try to give us the newly
          # created object.
          continue if children.length > 1
          loc_obj = scope.get(model_name)
          continue if !loc_obj? or loc_obj.id?
          entity = new iraModel(children[0], model_name)
          scope.set(model_name, entity.data)
    if xhrData['redirect']?
      return @_handle_payment_redirect(xhrData['redirect'], scope)
    else if @_redirect_if_needed(scope) == 'redirect'
      return true
    # Finally cleanup the form if present
    if scope? and scope.type == 'form'
      iraUtils.addClass(scope.root, 'ira-success')
      @_enable_again_submit scope.root
    true

  # @private
  _handle_payment_redirect: (redirect_data, scope) ->
    unless redirect_data?
      console.error 'No redirect data'
      return false

    iraApp.save(scope)

    if redirect_data['method'] == 'GET'
      document.location = redirect_data['url']
      return true

    # If it's not a GET request, we must build a hidden form and submit
    # it.
    form = document.createElement('FORM')
    form.setAttribute 'action', redirect_data['url']
    form.setAttribute 'method', redirect_data['method']
    for key, value of redirect_data['data']
      input = document.createElement('INPUT')
      input.setAttribute 'type', 'hidden'
      input.setAttribute 'name', key
      input.setAttribute 'value', value
      form.appendChild input
    document.body.appendChild form
    form.submit()

  # @private
  _enable_again_submit: (form) ->
    return unless form?
    # reactivate all submit buttons
    inputs = 'input[type=submit], button[type=submit]'
    for sub in form.querySelectorAll(inputs)
      sub.disabled = false
      sub.removeAttribute('disabled')
    iraUtils.removeClass(form, 'ira-processing')

  # @private
  _display_errors: (xhrData, form) ->
    return '' unless form?
    iraUtils.addClass(form, 'ira-error')
    @_enable_again_submit(form)

    if not xhrData['messages']? or xhrData.messages.length < 1
      errorMsg = xhrData.error
      errorMsg = xhrData.message if typeof(xhrData['message']) == 'string'
    else if typeof(xhrData.messages) == 'string'
      errorMsg = xhrData.messages
    else if Array.isArray(xhrData.messages)
      errorMsg = xhrData.messages.join(', ')
    else
      catchMsg = []
      for field_name, mes of xhrData.messages
        field_name = 'contact.email' if field_name == 'emails[0].email'
        elem = form.querySelector("[name$='#{field_name}']")
        help_span = null
        parent_group = iraUtils.getFormGroupParent(elem)
        if parent_group?
          iraUtils.addClass(parent_group, 'has-error')
          help_span = parent_group.querySelector('.ira-form-input-error')
        if help_span?
          help_span.textContent = mes
          continue
        console.warn \
          'No `ira-form-input-error\' block found for', field_name, mes
        catchMsg.push(mes)
      # If all field errors have successfully found their corresponding
      # field, we can safely return.
      return '' if catchMsg.length == 0
      # Else we have to display these lone messages in the general error
      # field.
      errorMsg = catchMsg.join(' ')

    bad_stuff =
      en_US: 'A technical error occurred. Please try again later.'
      nl_NL: 'Er is een technische fout opgetreden. Probeer het later opnieuw.' # noqa
      fr_FR: 'Une erreur technique est survenue. Veuillez réessayer plus tard.' # noqa
    if errorMsg == '' or errorMsg == 'INTERNAL_ERROR'
      lng = iraApp.main_scope.get('lang')
      errorMsg = bad_stuff[lng] if lng of bad_stuff

    general_errors = form.querySelectorAll('.ira-form-general-error')
    if general_errors.length > 0
      for elem in general_errors
        elem.textContent = errorMsg
        iraUtils.showElement(elem)
    else
      console.warn(
        'No `ira-form-general-error\' block found to display',
        errorMsg)
    errorMsg

  # @private
  _redirect_if_needed: (scope) ->
    goto = iraApp.main_scope.get 'redirect_url'
    iraApp.main_scope.remove 'redirect_url'
    iraApp.save(scope)
    return 'stay' if !iraUtils.isPresent(goto)
    document.location = goto
    'redirect'

  answersToMethod: (method_name) ->
    return false if method_name[0] == '_'
    return method_name of @


module.exports = iraMetaController
